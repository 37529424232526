/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Heading from "../Heading";
import Icon from "../Icon";
import styles from "./Alert.module.scss";

type Props = {
  children: React.Node,
  hasArrow?: boolean,
  iconSource?: SVGImport | string,
  isLight?: boolean,
  title?: React.Node,
  type?: "info" | "warning" | "error" | "success"
};

const iconMap = {
  info: Svg.Info,
  warning: Svg.Warning,
  error: Svg.Block,
  success: Svg.TickOutline
};

const Alert = ({
  children,
  hasArrow,
  iconSource,
  isLight,
  title,
  type = "warning"
}: Props) => (
  <div
    className={classnames(
      "alert",
      `alert--${type}`,
      styles["alert"],
      styles[`alert--${type}`],
      {
        "alert--light": isLight,
        "alert--arrow": hasArrow,
        [styles["alert--arrow"]]: hasArrow
      }
    )}
  >
    <div className="caption">
      <div
        className={classnames(
          "caption__media",
          "caption__media--top",
          "alert__media",
          styles["alert__media"]
        )}
      >
        <Icon
          className={styles["alert__icon"]}
          source={
            typeof iconSource === "string"
              ? Svg[iconSource]
              : iconSource
              ? iconSource
              : iconMap[type]
          }
        />
      </div>
      <div
        className={classnames(
          "caption__text",
          "caption__text--top",
          "alert__text",
          styles["alert__text"]
        )}
      >
        {title ? (
          <Heading level={5} component="strong" isLeading fontWeight="bold">
            {title}
          </Heading>
        ) : null}
        {children}
      </div>
    </div>
  </div>
);

export default Alert;
