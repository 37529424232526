/* @flow */

import classnames from "classnames";
import * as React from "react";
import styles from "./FlexTable.module.scss";

type Props = {
  children: React.Node,
  className?: string
};

type tableProps = {
  isContextual?: boolean,
  isAlt?: boolean
} & Props;

const FlexTable = ({
  children,
  isContextual,
  isAlt,
  className,
  ...props
}: tableProps) => {
  return (
    <div
      {...props}
      className={classnames("table", styles.table, className, {
        "table--contextual": isContextual,
        "table--alt": isAlt
      })}
    >
      {children}{" "}
    </div>
  );
};

FlexTable.Header = ({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={className}>
      {" "}
      {children}{" "}
    </div>
  );
};

FlexTable.Body = ({ children, className, ...props }: Props) => {
  return (
    <div
      {...props}
      className={classnames("table__body", styles.tbody, className)}
    >
      {" "}
      {children}{" "}
    </div>
  );
};

type tableRowProps = {
  isHeader?: boolean
} & Props;

FlexTable.Row = ({
  children,
  isHeader,
  className,
  ...props
}: tableRowProps) => {
  return (
    <div
      {...props}
      className={classnames("table__tr", styles.tr, className, {
        "table__tr--header": isHeader
      })}
    >
      {children}{" "}
    </div>
  );
};

type tableCellProps = {
  isAlignedRight?: boolean
} & Props;

FlexTable.Heading = ({
  children,
  className,
  isAlignedRight,
  ...props
}: tableCellProps) => {
  return (
    <div
      {...props}
      className={classnames(
        "table__th",
        styles.th,
        {
          [styles["align-right"]]: isAlignedRight
        },
        className
      )}
    >
      {" "}
      {children}{" "}
    </div>
  );
};

FlexTable.Data = ({
  children,
  className,
  isAlignedRight,
  ...props
}: tableCellProps) => {
  return (
    <div
      {...props}
      className={classnames(
        "table__td",
        styles.td,
        {
          [styles["align-right"]]: isAlignedRight
        },
        className
      )}
    >
      {" "}
      {children}{" "}
    </div>
  );
};

FlexTable.Footer = ({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={className}>
      {" "}
      {children}{" "}
    </div>
  );
};
export default FlexTable;
