/* @flow */

import * as React from "react";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Select from "../Select";
import FilterContext from "./_FilterContext";
import { type FilterGroupSelectProps } from "./types";

const _FilterGroupSelect = ({
  name,
  label,
  filterType,
  filterOptions,
  ...selectProps
}: FilterGroupSelectProps) => (
  <FilterContext.Consumer>
    {({ filters, activeFilters, setActiveFilters }) => {
      const onFilterChange = selectValues => {
        if (Array.isArray(selectValues)) {
          setActiveFilters({
            ...activeFilters,
            [name]: selectValues.map(value => value.value)
          });
        } else {
          setActiveFilters({
            ...activeFilters,
            [name]: selectValues ? [selectValues.value] : []
          });
        }
      };

      const selectedValues = filterOptions.map(option => {
        if (activeFilters[name].includes(option.value)) return option;
      });

      return (
        <form className="filters__form">
          <fieldset className="filters__filter-group gutter--bottom no-gutter--md-bottom">
            <legend className="filters__group-legend">{label}</legend>
            <ul className="flush--all no-gutter--bottom">
              <Select
                name={name}
                options={filterOptions}
                onChange={e => onFilterChange(e)}
                value={selectedValues}
                isClearable
                {...selectProps}
              />
            </ul>
          </fieldset>
        </form>
      );
    }}
  </FilterContext.Consumer>
);

export default _FilterGroupSelect;
