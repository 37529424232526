/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classNames from "classnames";
import * as React from "react";
import Alert from "../Alert";
import styles from "./Form.module.scss";

export type FormFieldProps = {
  type?: "field" | "group",
  label?: React.Node,
  name: string,
  hint?: React.Node,
  error?: string,
  isRequired?: boolean,
  isDisabled?: boolean,
  isErrorHidden?: boolean
};

const FORM_FIELD_ERROR_CLASS_NAME_PREFIX = "form__field--error--";
const FORM_LABEL_CLASS_NAME = "form__label";

export const getFormLabelSelector = (name: string) =>
  `.${FORM_FIELD_ERROR_CLASS_NAME_PREFIX}${name} .${FORM_LABEL_CLASS_NAME}`;

const FormField = ({
  type = "field",
  label,
  name,
  hint,
  error = "",
  isRequired = false,
  children,
  isDisabled = false,
  isErrorHidden = false
}: { children: React.Node } & FormFieldProps) => {
  const FieldComponent = type === "group" ? "fieldset" : "div";
  const LabelComponent = type === "group" ? "legend" : "label";
  const message = (
    <Alert iconSource={Svg.Block} isLight hasArrow>
      {error}
    </Alert>
  );
  return (
    <div>
      {error.length && !isDisabled ? (
        isErrorHidden ? (
          <div className="visually-hidden">{message}</div>
        ) : (
          message
        )
      ) : null}
      <FieldComponent
        className={`${FORM_FIELD_ERROR_CLASS_NAME_PREFIX}${name}`}
      >
        <LabelComponent>
          {label ? (
            <span
              className={classNames(
                FORM_LABEL_CLASS_NAME,
                {
                  "form__label--required": isRequired
                },
                styles["form__label"]
              )}
            >
              {label}
            </span>
          ) : null}
          {children}
        </LabelComponent>
        {hint ? (
          <p
            className={classNames("small", {
              "form__input--disabled": isDisabled
            })}
          >
            {hint}
          </p>
        ) : null}
      </FieldComponent>
    </div>
  );
};

export default FormField;
