/* @flow */
import * as React from "react";
import { components } from "react-select";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon source={Svg.ChevronDown} className="form__icon" />
      </components.DropdownIndicator>
    )
  );
};

export default DropdownIndicator;
