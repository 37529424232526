/* @flow */

import * as React from "react";

import Icon from "../Icon";
import OutsideClick from "../OutsideClick";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";

type Props = {
  /** An array of options, similar to a HTML <select> */
  children: React.ChildrenArray<React.Element<typeof LanguageDropDown.Option>>,
  /** The function called when the option changes */
  onChange?: string => void
};

const LanguageDropDown = ({ children, onChange }: Props) => {
  const [isOpen, toggleDropdown] = React.useState(false);
  const [selectedOption, changeSelectedOption] = React.useState(
    React.Children.toArray(children)[0].props.children
  );

  const firstRender = React.useRef(true);
  React.useEffect(() => {
    if (!firstRender.current && onChange) onChange(selectedOption);
    firstRender.current = false;
  }, [selectedOption]);

  return (
    <OutsideClick handler={() => toggleDropdown(false)} className="language">
      <div
        className={classnames("language__current", {
          "language__current--open": isOpen
        })}
        onClick={() => toggleDropdown(!isOpen)}
      >
        <span className="visually-hidden">menu</span>
        <span className="language__chosen">{selectedOption}</span>
        {!isOpen && (
          <Icon
            source={Svg.ChevronDown}
            size="small"
            className="language__icon language__icon--down"
          />
        )}
        {isOpen && (
          <Icon
            source={Svg.ChevronUp}
            size="small"
            className="language__icon"
          />
        )}
      </div>
      <ul
        className="language__options"
        style={{ display: isOpen ? "block" : "none" }}
      >
        {React.Children.map(children, child =>
          React.cloneElement(child, { changeSelectedOption, selectedOption })
        )}
      </ul>
    </OutsideClick>
  );
};

type OptionProps = {
  /** The label of the option */
  children: string,
  /** The default selected option */
  isSelected?: boolean,
  /** Do not override */
  changeSelectedOption?: string => void,
  /** Do not override */
  selectedOption?: string
};

LanguageDropDown.Option = ({
  children,
  isSelected,
  changeSelectedOption,
  selectedOption
}: OptionProps) => {
  React.useEffect(() => {
    if (isSelected && changeSelectedOption) changeSelectedOption(children);
  }, []);

  return (
    <li className="language__item">
      <div
        className="language__option"
        onClick={() => {
          if (changeSelectedOption) changeSelectedOption(children);
        }}
      >
        <span className="visually-hidden">menu item</span>
        <span className="language__selector">
          <span
            className={classnames("language__circle", {
              "language__circle--selected":
                isSelected || selectedOption === children
            })}
          ></span>
        </span>
        {children}
      </div>
    </li>
  );
};

export default LanguageDropDown;
