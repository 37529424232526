/* @flow */

import classnames from "classnames";
import * as React from "react";
import GridContext from "./_GridContext";

type Props = {
  children: React.Node,
  component?: React.ElementType,
  className?: string,
  hasHeightAuto?: boolean,
  isBulletList?: boolean,
  reorder?: "none" | "pull" | "push",
  horizontalAlign?: HorizontalAlign,
  verticalAlign?: VerticalAlign,
  width: Width,
  // NOTE: Desktop breakpoint
  lgHorizontalAlign?: HorizontalAlign,
  lgVerticalAlign?: VerticalAlign,
  lgWidth?: Width,
  // NOTE: Tablet breakpoint
  mdHorizontalAlign?: HorizontalAlign,
  mdVerticalAlign?: VerticalAlign,
  mdWidth?: Width,
  // NOTE: Mobile breakpoint
  smHorizontalAlign?: HorizontalAlign,
  smVerticalAlign?: VerticalAlign,
  smWidth?: Width
};

export default class GridItem extends React.Component<Props> {
  render() {
    const {
      children,
      component: Component = "div",
      className = "",
      isBulletList,
      reorder = "",
      width,
      horizontalAlign = "",
      verticalAlign = "",
      // NOTE: Desktop breakpoint
      lgWidth = "",
      lgHorizontalAlign = "",
      lgVerticalAlign = "",
      // NOTE: Tablet breakpoint
      mdWidth = "",
      mdHorizontalAlign = "",
      mdVerticalAlign = "",
      // NOTE: Mobile breakpoint
      smWidth = "",
      smHorizontalAlign = "",
      smVerticalAlign = ""
    } = this.props;

    return (
      <GridContext.Consumer>
        {({ hasHorizontalGutter, hasVerticalGutter }) => (
          <Component
            className={classnames(
              "grid__item",
              `grid__item--${width}`,
              {
                "grid__item--gutter": hasHorizontalGutter,
                "grid__item--gutter-vertical": hasVerticalGutter,
                "grid__item--bullet": isBulletList,
                [`grid__item--${reorder}`]: reorder && reorder !== "none",
                [`grid__item--align-${horizontalAlign}`]: horizontalAlign,
                [`grid__item--${verticalAlign}`]: verticalAlign,
                // NOTE: Desktop breakpoint
                [`grid__item--lg-${lgWidth}`]: lgWidth,
                [`grid__item--lg-align-${lgHorizontalAlign}`]: lgHorizontalAlign,
                [`grid__item--lg-${lgVerticalAlign}`]: lgVerticalAlign,
                // NOTE: Tablet breakpoint
                [`grid__item--md-${mdWidth}`]: mdWidth,
                [`grid__item--md-align-${mdHorizontalAlign}`]: mdHorizontalAlign,
                [`grid__item--md-${mdVerticalAlign}`]: mdVerticalAlign,
                // NOTE: Mobile breakpoint
                [`grid__item--sm-${smWidth}`]: smWidth,
                [`grid__item--sm-align-${smHorizontalAlign}`]: smHorizontalAlign,
                [`grid__item--sm-${smVerticalAlign}`]: smVerticalAlign
              },
              className
            )}
          >
            {children}
          </Component>
        )}
      </GridContext.Consumer>
    );
  }
}

type HorizontalAlign = "left" | "center" | "right";
type VerticalAlign = "top" | "middle" | "bottom";
type Width =
  | "1/1"
  | "1/2"
  | "1/3"
  | "2/3"
  | "1/4"
  | "3/4"
  | "1/5"
  | "2/5"
  | "3/5"
  | "4/5"
  | "1/6"
  | "5/6"
  | "1/7"
  | "2/7"
  | "3/7"
  | "4/7"
  | "5/7"
  | "6/7"
  | "1/8"
  | "3/8"
  | "5/8"
  | "7/8"
  | "1/9"
  | "2/9"
  | "4/9"
  | "5/9"
  | "7/9"
  | "8/9"
  | "1/10"
  | "3/10"
  | "7/10"
  | "9/10"
  | "1/11"
  | "2/11"
  | "3/11"
  | "4/11"
  | "5/11"
  | "6/11"
  | "7/11"
  | "8/11"
  | "9/11"
  | "10/11"
  | "1/12"
  | "5/12"
  | "7/12";
