/* @flow */

import classnames from "classnames";
import * as React from "react";

type Props = {
  children: React.Node,
  component?: React.ElementType,
  hasGutter?: boolean,
  hasSmallGutter?: boolean, // NOTE: Only applied to md and sm breakpoints.
  isLeading?: boolean,
  isTrailing?: boolean,
  isCard?: boolean,
  isNarrow?: boolean,
  hasBreak?: boolean,
  hasMdBreak?: boolean,
  color?:
    | "none"
    | "abbey"
    | "blue-lagoon"
    | "dove-gray"
    | "gallery"
    | "mine-shaft"
    | "ruled"
    | "ruled-top"
    | "silver"
    | "transparent"
    | "white"
    | "wild-sand",
  mdColor?: "none" | "gallery" | "wild-sand" | "silver" | "ruled" | "ruled-top",
  smColor?:
    | "none"
    | "gallery"
    | "wild-sand"
    | "silver"
    | "ruled"
    | "ruled-top"
    | "white"
};

const Section = ({
  children,
  component,
  hasGutter,
  hasSmallGutter,
  isLeading,
  isTrailing,
  isCard,
  isNarrow,
  hasBreak,
  hasMdBreak,
  color = "none",
  mdColor = "none",
  smColor = "none"
}: Props) => {
  const MarkupComponent = component || "div";

  return (
    <MarkupComponent
      className={classnames("section", {
        "section--gutter": hasGutter,
        "section--gutter-sm": hasSmallGutter,
        "section--gutter--leading": hasGutter && isLeading,
        "section--gutter--trailing": hasGutter && isTrailing,
        "section--break": hasBreak,
        "section--md-break": hasMdBreak,
        "section--card": isCard,
        "section--narrow": isNarrow,
        [`section--${color}`]: color !== "none",
        [`section--${color}--leading`]: color !== "none" && isLeading,
        [`section--${color}--trailing`]: color !== "none" && isTrailing,
        [`section--md-${mdColor}`]: mdColor !== "none",
        [`section--md-${mdColor}--leading`]: mdColor !== "none" && isLeading,
        [`section--sm-${smColor}`]: smColor !== "none",
        [`section--sm-${smColor}--leading`]: smColor !== "none" && isLeading
      })}
    >
      {children}
    </MarkupComponent>
  );
};

export default Section;
