// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Alert-module__alert--warning--1uc75 .Alert-module__alert__icon--3xwk4{color:#333}.Alert-module__alert--info--36ptd.Alert-module__alert--arrow--2Hfgd .Alert-module__alert__media--3b4X0::before,.Alert-module__alert--info--36ptd.Alert-module__alert--arrow--2Hfgd .Alert-module__alert__media--3b4X0::after{border-top-color:#00b0ca}.Alert-module__alert--error--3Mq_2.Alert-module__alert--arrow--2Hfgd .Alert-module__alert__media--3b4X0::before,.Alert-module__alert--error--3Mq_2.Alert-module__alert--arrow--2Hfgd .Alert-module__alert__media--3b4X0::after{border-top-color:#9c2aa0}.Alert-module__alert--success--17OGr{border-color:#428600}.Alert-module__alert--success--17OGr .Alert-module__alert__media--3b4X0{background-color:#428600;color:#fff}.Alert-module__alert--success--17OGr.Alert-module__alert--arrow--2Hfgd .Alert-module__alert__media--3b4X0::before,.Alert-module__alert--success--17OGr.Alert-module__alert--arrow--2Hfgd .Alert-module__alert__media--3b4X0::after{border-top-color:#428600}.Alert-module__alert__text--3vyjo{width:100%}\n", ""]);
// Exports
exports.locals = {
	"alert--warning": "Alert-module__alert--warning--1uc75",
	"alert__icon": "Alert-module__alert__icon--3xwk4",
	"alert--info": "Alert-module__alert--info--36ptd",
	"alert--arrow": "Alert-module__alert--arrow--2Hfgd",
	"alert__media": "Alert-module__alert__media--3b4X0",
	"alert--error": "Alert-module__alert--error--3Mq_2",
	"alert--success": "Alert-module__alert--success--17OGr",
	"alert__text": "Alert-module__alert__text--3vyjo"
};
module.exports = exports;
