/* @flow */

import * as React from "react";
import { type Context } from "./types";

const AccordionContext = React.createContext<Context>({
  active: {},
  toggleActive: () => {},
  isBilling: false
});

export default AccordionContext;
