/* @flow */

export default {
  control: (provided: Object, state: Object) => ({
    ...provided,
    border: state.isFocused ? "1px solid #00b0ca" : "1px solid #999",
    borderRadius: 0,
    boxShadow: "inset 1px 3px 4px 0 rgba(0,0,0,0.1)",
    color: "#666",
    paddingTop: "5px",
    paddingBottom: "4px",
    paddingLeft: "10px",
    outline: state.isFocused ? "1px solid #00b0ca" : "",
    "&:hover": {
      borderColor: ""
    }
  }),
  indicatorsContainer: (provided: Object) => ({
    ...provided,
    paddingRight: "6px"
  }),
  indicatorSeparator: () => ({}),
  menu: (provided: Object) => ({
    ...provided,
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: (provided: Object) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0
  }),
  option: (provided: Object) => ({
    ...provided,
    padding: "0 20px 0 20px"
  })
};
