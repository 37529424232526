/* @flow */

import classnames from "classnames";
import * as React from "react";
import styles from "./Badge.module.scss";

const Badge = ({
  children,
  label,
  isDot = false,
  type = "success",
  centerText = false,
  hasPadding = false,
  fullWidth = false
}: {
  children: React.Node,
  label?: string,
  isDot?: boolean,
  type?: "success" | "warning" | "danger" | "undecided" | "stateless",
  fullWidth?: boolean,
  hasPadding?: boolean,
  centerText?: boolean
}) => (
  <div
    className={classnames(styles["badge"], styles[`badge--${type}`], {
      [styles["badge--full-width"]]: fullWidth,
      [styles["badge--padding"]]: hasPadding,
      [styles["badge--centered"]]: centerText
    })}
  >
    {!label || <p className={styles["label"]}>{label}</p>}
    <div className={styles["box"]}>{children}</div>
  </div>
);

export default Badge;
