/* @flow */

import classnames from "classnames";
import * as React from "react";

type Props = {
  /** What's rendered inside of the button, this is normally text */
  children: React.Node,
  /** Defines the look of the button shown below */
  rank?: "primary" | "secondary" | "tertiary",
  /** Overrides the rank, makes the button transparent */
  transparent?: boolean,
  /** Typically used in context of darker backgrounds. */
  darkBackground?: boolean,
  /** Defines the shape of the button. */
  layout?: "default" | "fullWidth" | "square",
  /** Only applies to mobile breakpoint */
  inline?: boolean,
  /** Applies a 10px margin to the bottom of the button */
  trailing?: boolean,
  /** Disables the button and gives it a grey look, overrides rank and transparent */
  disabled?: boolean,
  /** Gives the button a green look, overrides the rank but not the transparent property */
  selected?: boolean,
  /** The function called when the button is pressed */
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  /** Any additional classnames passed to the button */
  className?: string,
  /** The ID used by the button */
  id?: string,
  /** Changes the button to redirect via the window.location method */
  isWindowRedirect?: boolean,
  /** The location the button will redirect to if the windowRedirect method is enabled */
  windowRedirectLocation?: string
};

const Button = ({
  children,
  rank = "primary",
  transparent,
  darkBackground,
  layout = "default",
  inline,
  trailing,
  disabled,
  selected,
  className,
  id,
  onClick,
  isWindowRedirect,
  windowRedirectLocation = "",
  ...props
}: Props) => {
  const windowRedirectOnClick = () => {
    window.location.href = `${window.location.origin}/${windowRedirectLocation}`;
  };

  return (
    <button
      type="button"
      className={classnames(
        "button",
        {
          [`button--${rank}`]: !transparent,
          [`button--${rank}--dark`]: !transparent && darkBackground,
          "button--full-width": layout === "fullWidth",
          "button--square": layout === "square",
          "button--trailing": trailing,
          "button--disabled": disabled,
          "button--confirm": selected,
          "button--inline": inline,
          "button--transparent": transparent
        },
        className
      )}
      onClick={isWindowRedirect ? windowRedirectOnClick : onClick}
      id={id}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
