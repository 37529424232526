/* @flow */

import * as React from "react";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import Icon from "../Icon";
import Clickable, { type ClickableAction } from "../Clickable";

type Props = {
  children?: React.Node,
  action?: ClickableAction,
  onClick?: () => void,
  heading: React.Node,
  isTextInline?: boolean,
  border?: "dark" | "red",
  icon?: SVGImport,
  /** There is a set of hi and mid SVGs which can't really be customised in terms of stroke size, fill or colour. Those icons should have the iconIsEmpty prop set to true. */
  iconIsEmpty?: boolean
};

const LinkTile = ({
  children,
  action,
  onClick,
  heading,
  isTextInline,
  border,
  icon,
  iconIsEmpty = true
}: Props) => (
  <Clickable
    className={classnames("link", "link--tile", {
      "link--tile--dark": border === "dark",
      "link--tile--red": border === "red"
    })}
    {...action}
    onClick={onClick}
    style={{ width: "100%" }}
  >
    <span className="chevron">
      <span className="chevron__text">
        <span className="media media--gutter media--middle">
          {!!icon && (
            <span className="link__icon media__image hide--sm">
              <Icon source={icon} size="large" isThin isEmpty={iconIsEmpty} />
            </span>
          )}
          <span className="media__body">
            <span
              className={classnames(
                "link__heading",
                "heading",
                "heading--4",
                "no-gutter--all",
                {
                  "heading--inline-block": isTextInline
                }
              )}
            >
              {heading}
            </span>
            <span
              className={classnames(
                "link__heading",
                "heading",
                "heading--regular",
                "heading--6",
                "no-gutter--all",
                {
                  "heading--inline-block": isTextInline
                }
              )}
            >
              {children}
            </span>
          </span>
        </span>
      </span>
      <span className="chevron__container">
        <Icon source={Svg.ChevronRight} className="chevron__icon--red" />
      </span>
    </span>
  </Clickable>
);

export default LinkTile;
