// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Accordion-module__accordion__heading--3Et00{width:100%}.Accordion-module__accordion__link-item--thxZ4{display:block;text-align:left;width:100%}\n", ""]);
// Exports
exports.locals = {
	"accordion__heading": "Accordion-module__accordion__heading--3Et00",
	"accordion__link-item": "Accordion-module__accordion__link-item--thxZ4"
};
module.exports = exports;
