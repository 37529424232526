/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Clickable from "../Clickable";
import Icon from "../Icon";

type Props = {
  /** An array of <Breadcrumbs.Item/> */
  children: React.ChildrenArray<React.Element<typeof Breadcrumbs.Item>>,
  /** The variation of the breadcrumbs to use as seen below */
  colour?: "Default" | "Dark" | "Grey" | "Transparent"
};

const Breadcrumbs = ({ children, colour = "Default" }: Props) => {
  return (
    <nav
      className={classnames("breadcrumbs", {
        "breadcrumbs--extrude": colour === "Default",
        "breadcrumbs--dark": colour === "Dark",
        "breadcrumbs--abbey": colour === "Grey",
        "breadcrumbs--transparent": colour === "Transparent"
      })}
    >
      <div className="spring">
        <h2 className="visually-hidden">Breadcrumbs for the current page</h2>
        <ol className="breadcrumbs__list hide--sm hide--md">
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              isFirst: index === 0,
              isLink: !(index + 1 === React.Children.count(children))
            })
          )}
        </ol>
        <span className="hide--lg">
          {React.Children.toArray(children)[React.Children.count(children) - 2]}
        </span>
      </div>
    </nav>
  );
};

type ItemProps = {
  label: string,
  isLink?: boolean,
  isFirst?: boolean,
  type?: "routerLink" | "htmlLink"
};

const _BreadcrumbsItem = ({
  label,
  isLink = true,
  isFirst = false,
  type = "htmlLink",
  ...props
}: ItemProps) => (
  <li className="breadcrumbs__item">
    {!isLink ? (
      <span className="breadcrumbs__crumb breadcrumbs__crumb--current">
        {label}
      </span>
    ) : (
      <>
        {/* $FlowFixMe: false negative */}
        <Clickable
          type={type}
          {...props}
          className={classnames("breadcrumbs__crumb", {
            "breadcrumbs__crumb--first": isFirst
          })}
        >
          {label}
        </Clickable>
        <Icon source={Svg.ChevronRight} className="breadcrumbs__chevron" />
      </>
    )}
  </li>
);

Breadcrumbs.Item = _BreadcrumbsItem;
export default Breadcrumbs;
