/* @flow */

import classnames from "classnames";
import * as React from "react";

type Props = {
  children: React.Node,
  title?: React.Node,
  hasGutter?: boolean,
  theme?: "white" | "abbey" | "wildSand",
  isContentNarrow?: boolean,
  isHeaderNarrow?: boolean
};

const themeMap = {
  white: "white",
  abbey: "dark",
  wildSand: "light"
};

const Card = ({
  title,
  children,
  hasGutter,
  theme = "white",
  isContentNarrow,
  isHeaderNarrow
}: Props) => {
  return (
    <div
      className={classnames("card", `card--${themeMap[theme]}`, {
        "card--gutter": hasGutter
      })}
    >
      {title ? (
        <div
          className={classnames("card__header", {
            "card__header--narrow": isHeaderNarrow
          })}
        >
          {title}
        </div>
      ) : null}
      <div
        className={classnames("card__content", {
          "card__content--narrow": isContentNarrow
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
