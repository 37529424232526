/* @flow */

import classnames from "classnames";
import * as React from "react";
import { RootConsumer } from "../Root";
import SlideToggle from "../SlideToggle";
import AccordionItemContext from "./_AccordionItemContext";

type Props = {
  component: React.ElementType,
  className?: string,
  children?: React.Node,
  onAnimationCompleted?: (isOpen: boolean) => void,
  isBilling?: boolean
};
type State = void;

class ControlledContent extends React.Component<Props & { isActive: boolean }> {
  _ref = React.createRef();

  _handleAnimationCompleted = (isOpen: boolean, shouldFocus: boolean): void => {
    const element: ?HTMLElement = this._ref.current;

    /* istanbul ignore next */
    if (!element) {
      return;
    }

    if (shouldFocus) {
      if (isOpen) {
        element.focus();
      } else {
        element.blur();
      }
    }

    if (this.props.onAnimationCompleted) {
      this.props.onAnimationCompleted(isOpen);
    }
  };

  render() {
    const {
      isActive,
      component: Component,
      className = "",
      children,
      isBilling,
      ...props
    } = this.props;
    const transitionType = "cubic-bezier(0.770, 0.000, 0.175, 1.000)";
    return (
      <RootConsumer>
        {({ mouseActive }) => (
          <SlideToggle
            onAnimationCompleted={isOpen => {
              this._handleAnimationCompleted(isOpen, !mouseActive);
            }}
            transitionType={transitionType}
            transitionTime={isActive ? 670 : 1000}
            isOpen={isActive}
          >
            <Component
              className={classnames(
                {
                  accordion__content: !isBilling,
                  "bills-accordion__content": isBilling
                },
                className
              )}
              tabIndex={isActive ? 0 : undefined}
              ref={this._ref}
              {...props}
            >
              {children}
            </Component>
          </SlideToggle>
        )}
      </RootConsumer>
    );
  }
}

export default class AccordionContent extends React.Component<Props, State> {
  static defaultProps = {
    component: "div"
  };

  render() {
    const {
      className,
      component,
      children,
      onAnimationCompleted,
      ...props
    } = this.props;
    return (
      <AccordionItemContext.Consumer>
        {({ isActive, isBilling }) => (
          <ControlledContent
            isActive={isActive}
            component={component}
            className={className}
            isBilling={isBilling}
            onAnimationCompleted={onAnimationCompleted}
            {...props}
          >
            {children}
          </ControlledContent>
        )}
      </AccordionItemContext.Consumer>
    );
  }
}
