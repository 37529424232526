/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Icon from "../Icon";
import localStyles from "./Overlay.module.scss";

type Props = {
  children: React.Node,
  contentSize?: "sm" | "md",
  className?: String,
  id?: String,
  onClose?: (status: boolean) => void,
  isOpen?: boolean,
  hideClose?: boolean,
  /** Focus will be given to the container when the isOpen property is changed */
  focusOnLoad?: boolean
};

const Overlay = ({
  children,
  contentSize,
  className,
  id,
  onClose,
  isOpen = true,
  hideClose = false,
  focusOnLoad = false
}: Props) => {
  const overlayRef = React.useRef(null);
  const [isOpened, toggle] = React.useState(isOpen);
  const toggleOverlay = () => {
    toggle(!isOpened);
    onClose && onClose((!isOpened: boolean));
  };
  React.useEffect(() => {
    toggle(isOpen);
    if (isOpen && overlayRef.current && focusOnLoad) {
      overlayRef.current.focus();
    }
  }, [isOpen]);
  return (
    <div
      className={classnames(
        "dialog",
        { "dialog--scrollable dialog--interstitial dialog--display": isOpened },
        className
      )}
      id={id}
      tabIndex={0}
      ref={overlayRef}
    >
      {!hideClose && (
        <div
          className={classnames("spring", "dialog__close-spring")}
          onClick={() => toggleOverlay()}
        >
          <div
            className={classnames(
              "dialog__close",
              localStyles["overlay__close"]
            )}
            tabIndex={0}
            onKeyPress={e => {
              if (e.key === "Enter") {
                toggleOverlay();
              }
            }}
          >
            <Icon source={Svg.Close} className="dialog__icon" />
          </div>
        </div>
      )}
      <div className="dialog__content">
        <div
          className={classnames("spring", {
            "spring--sm": contentSize === "sm",
            "spring--md": contentSize === "md"
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Overlay;
