/* @flow */

import * as React from "react";
import Icon from "../Icon";
import Clickable from "../Clickable";
import classnames from "classnames";
import localStyles from "./Dial.module.scss";
import type { ClickableAction } from "../Clickable/Clickable";

type Props = {
  action: ClickableAction,
  alt: string,
  icon: SVGImport
};

const DialCTA = ({ action, alt, icon }: Props) => (
  <Clickable className="dial__cta" {...action}>
    {!!icon && (
      <Icon
        source={icon}
        className={classnames("dial__cta-icon", localStyles.icon)}
      />
    )}
    <span className="visually-hidden">{alt}</span>
  </Clickable>
);

export default DialCTA;
