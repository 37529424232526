/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Icon from "../Icon";

type Props = {
  children?: React.Node,
  title?: React.Node,
  isLight?: boolean,
  isNew?: boolean,
  isRight?: boolean,
  className?: string,
  isTyping?: boolean,
  isChat?: string | boolean
};

const _Bubble = ({
  children,
  isLight = false,
  isNew = false,
  isRight = false,
  isChat,
  className,
  isTyping = false
}: Props) => {
  return (
    <div
      className={classnames("bubble", className, {
        "bubble--negative": isLight,
        "bubble--new": isNew,
        "bubble--typing": isTyping,
        "bubble--right": isRight,
        "chat-room__bubble": isChat,
        "chat-room__bubble--question": isChat === "question"
      })}
    >
      {children}
      <Icon
        source={Svg.Tail}
        className={classnames("bubble__tail", "icon--fill", "icon--thin")}
      />
    </div>
  );
};

export default _Bubble;
