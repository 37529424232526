/* @flow */
import classnames from "classnames";
import { Slide, Slider, WithStore } from "pure-react-carousel";
import * as React from "react";
import styles from "./Carousel.module.scss";
import Carousel from "./Carousel.web";
import CarouselControls from "./_CarouselControls.web";
import CarouselPagination from "./_CarouselPagination.web";

type Props = {
  /** An array of <Carousel.Item/> */
  children: React.ChildrenArray<React.Element<typeof Carousel.Item>>,
  /** Define if the controls should be visible. */
  showControls: boolean,
  /** Define if slide pagination should be visible. */
  showPagination: boolean,
  /** The current selected slide index. */
  currentSlide: number
};

const CarouselContent = React.forwardRef(
  ({ children, showControls, showPagination, currentSlide }: Props, ref) => (
    <>
      <Slider
        className="carousel"
        classNameTrayWrap="carousel__wrapper"
        classNameTray="carousel__slider"
        classNameAnimation={styles["carousel__slider--animation"]}
      >
        {React.Children.map(children, (child, index) => (
          <Slide
            key={index}
            index={index}
            className={classnames(
              "carousel__slide",
              styles["carousel__slide--middle"],
              { "carousel__slide--active": index === currentSlide }
            )}
          >
            {child}
          </Slide>
        ))}
      </Slider>
      {showControls && <CarouselControls />}
      {showPagination && <CarouselPagination />}
    </>
  )
);

export default WithStore(CarouselContent, state => ({
  currentSlide: state.currentSlide
}));
