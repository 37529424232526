/* @flow */

import classnames from "classnames";
import * as React from "react";

type Props = {
  source: SVGImport,
  className?: string,
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large",
  isThin?: boolean,
  isFilled?: boolean,
  isCaptioned?: boolean,
  hasPadding?: boolean,
  isEmpty?: boolean,
  isFocusable?: boolean
};

const Icon = ({
  source: MarkupComponent,
  className,
  size = "small",
  isThin,
  isFilled,
  isCaptioned,
  hasPadding,
  isEmpty,
  isFocusable = false
}: Props) => {
  if (!MarkupComponent) {
    return <></>;
  }
  return (
    <MarkupComponent
      className={classnames(
        "icon",
        {
          [`icon--${size}`]: size,
          "icon--thin": isThin,
          "icon--fill": isFilled,
          "icon--captioned": isCaptioned,
          "icon--pad": hasPadding,
          "icon--empty": isEmpty
        },
        className
      )}
      focusable={isFocusable.toString()}
      aria-hidden="true"
    />
  );
};

export default Icon;
