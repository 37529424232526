/* @flow */

import * as React from "react";
import classnames from "classnames";

const RootContext = React.createContext<{ mouseActive: boolean }>({
  mouseActive: false
});

type Props = {
  children?: React.Node
};

type State = {
  mouseActive: boolean
};

export default class Root extends React.Component<Props, State> {
  state = { mouseActive: false };

  _handleMouseDown = () => {
    this.setState({ mouseActive: true });
  };

  _handleKeyDown = () => {
    this.setState({ mouseActive: false });
  };

  render() {
    const { children } = this.props;
    const { mouseActive } = this.state;
    return (
      <div
        className={classnames({
          "mouse-active": mouseActive
        })}
        onMouseDown={this._handleMouseDown}
        onKeyDown={this._handleKeyDown}
      >
        <RootContext.Provider value={this.state}>
          {children}
        </RootContext.Provider>
      </div>
    );
  }
}

export const RootConsumer = RootContext.Consumer;
