/* @flow */
import React, { type Node } from "react";
import classNames from "classnames";

const breakpoints = ["sm", "md", "lg"];

type aspectRatio =
  | "1/1"
  | "1/2"
  | "1/3"
  | "2/3"
  | "1/4"
  | "3/4"
  | "1/5"
  | "2/5"
  | "3/5"
  | "4/5"
  | "1/6"
  | "5/6"
  | "auto";

type Props = {
  /** Max-width of 639px  */
  sm?: aspectRatio,
  /** Max-width of 1024px  */
  md?: aspectRatio,
  /** Max-width of 1844px  */
  lg?: aspectRatio,
  /** Handles the aspect ratio of all sizes ( except the ones specified )  */
  allBreakpoints?: aspectRatio,
  /** Measuring unit for the box width  */
  measureUnit?: "px" | "em" | "rem",
  /** Width of the box*/
  width?: number,
  children?: Node
};

const AspectHeight = ({
  children,
  width,
  measureUnit = "px",
  sm = "auto",
  md = "auto",
  lg = "auto",
  allBreakpoints = "auto"
}: Props) => {
  return (
    <div
      className={classNames("aspect-height", {
        [`aspect-height--sm-${sm}`]: sm,
        [`aspect-height--md-${md}`]: md,
        [`aspect-height--lg-${lg}`]: lg,
        [`aspect-height--${allBreakpoints}`]: allBreakpoints
      })}
      style={width && { width: `${width}${measureUnit}` }}
    >
      <div className="aspect-height__content">{children}</div>
    </div>
  );
};

export default AspectHeight;
