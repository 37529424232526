/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classNames from "classnames";
import * as React from "react";
import uuidv4 from "uuid/v4";
import Icon from "../Icon";
import FormField, { type FormFieldProps } from "./_FormField";

type Props = {
  name: string,
  placeholder?: string,
  options: $ReadOnly<{ [label: string]: string }>,
  value?: string,
  isDisabled?: boolean,
  isCaptioned?: boolean,
  isLight?: boolean,
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void
} & FormFieldProps;

const FormSelect = ({
  label,
  name,
  options,
  placeholder,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  isCaptioned = false,
  isLight = false,
  onFocus,
  onChange,
  onBlur
}: Props) => {
  return (
    <FormField
      {...{
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden
      }}
    >
      <div
        className={classNames("form__input", "form__input--selectable", {
          "form__input--caption": isCaptioned,
          "form__input--light": isLight,
          "form__input--disabled": isDisabled
        })}
      >
        <select
          {...{ name, value, onFocus, onChange, onBlur }}
          className="form__select"
          defaultValue={value}
          disabled={isDisabled}
        >
          {placeholder ? <option value="">{placeholder}</option> : null}
          {Object.entries(options).map(([label, option]) => (
            <option key={uuidv4()} value={option}>
              {label}
            </option>
          ))}
        </select>
        <Icon source={Svg.ChevronDown} className="form__icon" />
      </div>
    </FormField>
  );
};

export default FormSelect;
