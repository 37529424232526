/* @flow */
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Heading from "../Heading";
import Icon from "../Icon";
import Button from "../Button";
import Image from "../Image";
import Rating from "../Rating";

type Props = {
  /** The brand of the product, displayed in bold at the top */
  brand: string,
  /** The name of the product displayed below the brand. It's also used as the alt for the image */
  accessoryName: string,
  image: string,
  rating: number,
  cost: number,
  was?: number,
  /** The function called when the action button is clicked  */
  action: () => void,
  /** The label of the action button */
  actionLabel?: string
};

const Accessories = ({
  brand,
  accessoryName,
  image,
  rating,
  cost,
  was,
  action,
  actionLabel = "Add Item"
}: Props) => {
  return (
    <div className="accessory">
      <div className="accessory__content">
        <div className="accessory__header">
          <Heading component="h3" level={4} fontWeight="light">
            <strong className="accessory__brand">{brand}</strong>
            {accessoryName}
          </Heading>
        </div>
        <div className="accessory__image-container">
          <Image src={image} alt={accessoryName} className="accessory__image" />
        </div>
        <div className="accessory__rating-price-container">
          <div>
            <Rating rating={rating} />
            <Heading component="p" level={4} fontWeight="bold" hasNoGutter>
              Now £{cost}
            </Heading>
            {was && (
              <p className="accessory__was">
                was <span className="accessory__strikethrough">£{was}</span>
              </p>
            )}
            {!was && <p className="no-gutter--all">&nbsp;</p>}
          </div>
        </div>
        <Button
          rank="secondary"
          className="accessory__button"
          layout="fullWidth"
          onClick={action}
        >
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

export default Accessories;
