/* @flow */

import classNames from "classnames";
import pick from "lodash.pick";
import React from "react";
import styles from "./Form.module.scss";

export type Props = {
  accent?: "grey" | "red" | "green",
  label: string,
  name: string,
  value?: string,
  isRequired?: boolean,
  isChecked?: boolean,
  isDisabled?: boolean,
  darkBackground?: boolean,
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void
};

const FormToggleButton = ({
  accent = "grey",
  label,
  value = "",
  hint,
  isRequired = false,
  isChecked = false,
  isDisabled = false,
  darkBackground = false,
  onChange,
  ...props
}: Props) => {
  const nullable = !isRequired && isChecked;
  return (
    <label
      className={classNames(styles["form__toggle-button"], {
        [styles["form__toggle-button--grey"]]: accent === "grey",
        [styles["form__toggle-button--green"]]: accent === "green",
        [styles["form__toggle-button--red"]]: accent === "red",
        [styles["form__toggle-button--checked"]]: isChecked,
        [styles["form__toggle-button--dark"]]: darkBackground
      })}
    >
      {label}
      <input
        {...pick(props, ["name", "onFocus", "onBlur"])}
        {...(nullable ? { onClick: onChange } : { onChange })}
        className="visually-hidden"
        type="radio"
        value={nullable ? "" : value}
        required={isRequired}
        checked={isChecked}
        disabled={isDisabled}
      />
    </label>
  );
};

export default FormToggleButton;
