/* @flow */

import React, { useEffect, useRef, useState } from "react";

import FilterBody from "./_FilterBody.web";
import FilterButton from "./_FilterButton.web";
import FilterContext from "./_FilterContext";
import FilterHeading from "./_FilterHeading.web";
import FilterIcon from "./_FilterIcon.web";
import { type FilterProps } from "./types";
import FilterSort from "./_FilterSort.web";
import FilterSortOption from "./_FilterSortOption.web";
import { Svg } from "@vf-dcl/vodafone-ws2";

const Filter = ({
  children,
  filters,
  initialFilter = {},
  defaultOpen,
  onChange
}: FilterProps) => {
  const [isOpen, setIsOpen] = useState(!!defaultOpen);

  const emptyFilters = filters.reduce(
    (acc, cur) => ({ ...acc, [cur.name]: [] }),
    { [filters[0].name]: [] }
  );

  const [activeFilters, setActiveFilters] = useState({
    ...emptyFilters,
    ...initialFilter
  });

  const toggleSidebar = () => setIsOpen(!isOpen);

  const clearFilters = () => {
    setActiveFilters(emptyFilters);
  };

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false;
    else onChange(activeFilters);
  }, [activeFilters]);

  return (
    <FilterContext.Provider
      value={{
        isOpen,
        toggleSidebar,
        filters,
        activeFilters,
        setActiveFilters,
        clearFilters
      }}
    >
      <div className="filters__container--no-wrap flush--md-all">
        <div className="filters">{children}</div>
      </div>
    </FilterContext.Provider>
  );
};

Filter.Heading = FilterHeading;
Filter.Button = FilterButton;
Filter.Icon = FilterIcon;
Filter.Sort = FilterSort;
Filter.SortOption = FilterSortOption;
Filter.Body = FilterBody;
Filter.Context = FilterContext;

export default Filter;
