/* @flow */
import * as React from "react";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import Icon from "../Icon";
import Image from "../Image";
import Clickable from "../Clickable";
import Heading from "../Heading";
import Rating from "../Rating";

type Props = {
  /** A rating out of 5 stars */
  rating?: number,
  /** The title for the device */
  device: string,
  /** The cost of the device. */
  cost: number,
  /** The monthly cost. */
  installmentCost: number,
  /** The name of the bundle. */
  bundleName: string,
  /** An image path of the device. */
  image: string
};

const Devices = ({
  device,
  rating,
  cost,
  installmentCost,
  bundleName,
  image
}: Props) => (
  <Clickable className="align--center">
    <Heading level={4} fontWeight="light" isLeading>
      {device}
    </Heading>
    <Image src={image} alt="Apple iPhone 6s" />
    <ul className="list list--reset">
      <li className="gutter--top">
        <Rating rating={rating} />
      </li>
      <li>
        <strong>£{cost}</strong> one-off cost
      </li>
      <li>
        <strong>£{installmentCost}</strong> <abbr title="per">/</abbr>month on{" "}
        {bundleName}
      </li>
    </ul>
  </Clickable>
);

export default Devices;
