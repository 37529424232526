// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AdvancedTable-module__advanced-table--selected-column--3DhSI{color:#e60000}.AdvancedTable-module__advanced-table--control-width--2Qs3f table{width:auto}.AdvancedTable-module__advanced-table__resizable-body--1Sz8O{border-top:none !important}.AdvancedTable-module__advanced-table__resizer--3inpM{background:#ccc;width:2px;position:relative;touch-action:none;margin:10px 0 10px 0;border-radius:15%}.AdvancedTable-module__advanced-table__resizer--is-resizing--3S5qe{background:#666}\n", ""]);
// Exports
exports.locals = {
	"advanced-table--selected-column": "AdvancedTable-module__advanced-table--selected-column--3DhSI",
	"advanced-table--control-width": "AdvancedTable-module__advanced-table--control-width--2Qs3f",
	"advanced-table__resizable-body": "AdvancedTable-module__advanced-table__resizable-body--1Sz8O",
	"advanced-table__resizer": "AdvancedTable-module__advanced-table__resizer--3inpM",
	"advanced-table__resizer--is-resizing": "AdvancedTable-module__advanced-table__resizer--is-resizing--3S5qe"
};
module.exports = exports;
