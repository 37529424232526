/* @flow */

import * as React from "react";

import {
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";

import AdvancedTableBody from "./_AdvancedTableBody.web";
import AdvancedTableCheckBoxCell from "./_AdvancedTableCheckBoxCell.web";
import AdvancedTableCheckBoxHeader from "./_AdvancedTableCheckBoxHeader.web";
import AdvancedTableContext from "./_AdvancedTableContext";
import AdvancedTableEditableCell from "./_AdvancedTableEditableCell.web";
import AdvancedTableExpandCell from "./_AdvancedTableExpandCell.web";
import AdvancedTablePagination from "./_AdvancedTablePagination.web";
import AdvancedTableSimpleHeader from "./_AdvancedTableSimpleHeader.web";
import AdvancedTableSortingHeader from "./_AdvancedTableSortingHeader.web";
import AdvancedTableTable from "./_AdvancedTableTable.web";
import classnames from "classnames";
import styles from "./AdvancedTable.module.scss";

type Props = {
  children: React.Node,
  data: Array<any>,
  columns: Array<{
    Header: React.Node,
    accessor?: string
  }>,
  pageSize?: number,
  defaultColumnSort?: "ASC" | "DSC",
  checkBoxOnChange?: (Array<string>) => void,
  setWidthManually?: boolean,
  layout?: "default" | "fixed" | "flex",
  defaultSortColumnId?: string,
  columnOrder?: Array<string>,
  hiddenColumns?: Array<string>,
  filters?: Array<{
    id: string,
    value: any
  }>,
  inlineEditingEnabled?: boolean,
  resizeEnabled?: boolean,
  onCellEdit?: () => void,
  rowSubComponent?: React.ComponentType<any>,
  /** Disables the ability to sort all columns */
  disableSortBy?: boolean
};

const AdvancedTable = ({
  children,
  data,
  columns,
  pageSize = 1000,
  checkBoxOnChange,
  setWidthManually,
  defaultColumnSort = "DSC",
  layout = setWidthManually ? "fixed" : "default",
  defaultSortColumnId,
  columnOrder = React.useMemo(() => [], []),
  hiddenColumns = React.useMemo(() => [], []),
  filters = React.useMemo(() => [], []),
  inlineEditingEnabled,
  resizeEnabled,
  onCellEdit,
  rowSubComponent,
  disableSortBy,
  ...props
}: Props) => {
  let defaultColumn = {};
  if (inlineEditingEnabled) {
    defaultColumn = {
      ...defaultColumn,
      Cell: AdvancedTableEditableCell
    };
  }
  if (resizeEnabled) {
    defaultColumn = {
      ...defaultColumn,
      minWidth: 30,
      width: 150,
      maxWidth: 400
    };
  }
  const MarkupComponent = rowSubComponent;
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (MarkupComponent ? <MarkupComponent row={row} /> : null),
    []
  );

  const {
    canPreviousPage,
    canNextPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    nextPage,
    previousPage,
    selectedFlatRows,
    flatColumns,
    gotoPage,
    state: { selectedRowIds, pageIndex, expanded }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: defaultSortColumnId,
            desc: defaultColumnSort === "DSC"
          }
        ],
        pageIndex: 0
      },
      disableSortBy: disableSortBy,
      onCellEdit,
      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...state,
            pageSize: pageSize,
            columnOrder: columnOrder,
            hiddenColumns: hiddenColumns,
            filters: filters
          }),
          [state, pageSize, filters, columnOrder, hiddenColumns]
        );
      },
      ...props
    },
    useFilters,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    useColumnOrder,
    layout === "fixed"
      ? useBlockLayout
      : layout === "flex"
      ? useFlexLayout
      : "",
    resizeEnabled ? useResizeColumns : ""
  );

  //If defined calls the checkBoxOnChange using the array of values stored in state
  React.useEffect(() => {
    if (checkBoxOnChange) {
      checkBoxOnChange(selectedRowIds);
    }
  }, [selectedRowIds]);

  React.useEffect(() => {
    gotoPage(0);
  }, [pageSize]);

  return (
    <AdvancedTableContext.Provider
      value={{
        gotoPage,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        pageIndex,
        nextPage,
        previousPage,
        selectedFlatRows,
        flatColumns,
        renderRowSubComponent,
        resizeEnabled,
        canPreviousPage,
        canNextPage,
        layout,
        state: {
          selectedRowIds,
          pageSize
        }
      }}
    >
      <div
        className={classnames({
          [styles["advanced-table--control-width"]]:
            layout === "flex" || layout === "fixed"
        })}
      >
        {children}{" "}
      </div>{" "}
    </AdvancedTableContext.Provider>
  );
};

AdvancedTable.CheckBoxHeader = AdvancedTableCheckBoxHeader;
AdvancedTable.CheckBoxCell = AdvancedTableCheckBoxCell;
AdvancedTable.SimpleHeader = AdvancedTableSimpleHeader;
AdvancedTable.SortingHeader = AdvancedTableSortingHeader;
AdvancedTable.Pagination = AdvancedTablePagination;
AdvancedTable.Body = AdvancedTableBody;
AdvancedTable.Table = AdvancedTableTable;
AdvancedTable.EditableCell = AdvancedTableEditableCell;
AdvancedTable.ExpandCell = AdvancedTableExpandCell;
AdvancedTable.Context = AdvancedTableContext;

export default AdvancedTable;
