/* @flow */

import * as React from "react";

import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";

type Props = {
  /** Flips the Icon and puts the text on the right side */
  reversed?: boolean,
  /** The text rendered inside the icon, recommended to stick to a max of 9 characters */
  children: React.Node,
  /** Classnames given to the "brand-outline" container */
  containerClassName?: string,
  /** Classnames given to the "brand-outline__text" text container */
  textClassName?: string,
  /** Defines the colour */
  color?: "white" | "red"
};

const BrandOutline = ({
  reversed,
  children,
  containerClassName,
  textClassName,
  color
}: Props) => (
  <div
    className={classnames("brand-outline", containerClassName, {
      "brand-outline--white": color === "white",
      "brand-outline--red": color === "red",
      "brand-outline--reversed": reversed
    })}
  >
    <h1 className={classnames("brand-outline__text", textClassName)}>
      {children}
    </h1>
    <Icon
      className="brand-outline__icon"
      source={reversed ? Svg.BrandOutlineReversed : Svg.BrandOutline}
    />
  </div>
);

export default BrandOutline;
