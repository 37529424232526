/* @flow */

import React, { useState } from "react";
import classnames from "classnames";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Clickable from "../Clickable";
import Icon from "../Icon";
import FilterContext from "./_FilterContext";
import { type FilterGroupInputProps } from "./types";

const _FilterGroupInput = ({
  name,
  label,
  filterType,
  filterOptions,
  isOpen = true,
  hideOptions
}: FilterGroupInputProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(isOpen);
  const [hiddenOptions, setHiddenOptions] = useState(hideOptions);

  return (
    <FilterContext.Consumer>
      {({ filters, activeFilters, setActiveFilters }) => {
        const onFilterChange = e => {
          const { type, value, name } = e.target;

          if (type === "radio") {
            setActiveFilters({
              ...activeFilters,
              [name]: [value]
            });
          } else if (type === "checkbox") {
            let updatedFilters = { ...activeFilters };
            updatedFilters[name].includes(value)
              ? updatedFilters[name].splice(
                  updatedFilters[name].indexOf(value),
                  1
                )
              : updatedFilters[name].push(value);

            setActiveFilters(updatedFilters);
          }
        };

        return (
          <form className="filters__form">
            <fieldset
              className={classnames(
                "filters__filter-group gutter--bottom",
                "no-gutter--md-bottom",
                { "filters__input-list--active": isFilterOpen }
              )}
            >
              <legend
                className="filters__group-legend"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                {label}
                <div className="float--right">
                  <Icon
                    source={Svg.ChevronDown}
                    size="extra-small"
                    className="actions__icon actions__icon--chevron"
                  />
                </div>
              </legend>
              <ul
                className={classnames("filters__input-list flush--all", {
                  "filters__filter-group--active": !hiddenOptions
                })}
              >
                {filterOptions.map(({ label, value }, index) => (
                  <li className="filters__input-item" key={value + index}>
                    <label
                      className={classnames("filters__input-list-label", {
                        "filters__input-item--hidden":
                          hideOptions &&
                          hiddenOptions &&
                          index >= hideOptions.limitTo
                      })}
                    >
                      <input
                        className="filters__input"
                        name={name}
                        type={filterType}
                        value={value}
                        onChange={e => onFilterChange(e)}
                        checked={activeFilters[name].includes(value)}
                      />
                      {label}
                    </label>
                  </li>
                ))}
                {hideOptions && (
                  <li
                    className={classnames({
                      "filters__filter-group--active": !hiddenOptions
                    })}
                  >
                    <Clickable
                      className="filters__view-all gutter--bottom gutter--sm-left flush--all"
                      onClick={() => setHiddenOptions(false)}
                    >
                      {hideOptions.expandLabel}
                    </Clickable>
                    <Clickable
                      className="filters__view-all--toggle gutter--bottom gutter--sm-left flush--all"
                      onClick={() => setHiddenOptions(true)}
                    >
                      {hideOptions.collapseLabel}
                    </Clickable>
                  </li>
                )}
              </ul>
            </fieldset>
          </form>
        );
      }}
    </FilterContext.Consumer>
  );
};

export default _FilterGroupInput;
