/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import invariant from "invariant";
import React from "react";
import Icon from "../Icon";
import styles from "./Form.module.scss";
import FormField, { type FormFieldProps } from "./_FormField";

type Props = {
  type?: "text" | "number" | "password" | "email" | "date",
  name: string,
  isIcon?: boolean,
  iconSource?: SVGImport,
  placeholder?: string,
  value?: string,
  isDisabled?: boolean,
  isRequired?: boolean,
  isCaptioned?: boolean,
  isShaded?: boolean,
  isLight?: boolean,
  isRound?: boolean,
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void,
  maxLength?: number,
  readOnly?: boolean
} & FormFieldProps;

const FormInput = ({
  label,
  type = "text",
  name,
  placeholder,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  isCaptioned = false,
  isShaded = false,
  isLight = false,
  isRound = false,
  isIcon,
  iconSource,
  onFocus,
  onChange,
  onBlur,
  maxLength,
  readOnly = false,
  ...props
}: Props) => {
  invariant(
    ["text", "number", "password", "email", "date"].includes(type),
    `FormInput: Type can only be "text", "password", "number" and "email" types. ${type} given`
  );
  return (
    <FormField
      {...{
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden
      }}
    >
      <span className={styles["form--input"]}>
        <input
          {...{
            type,
            name,
            placeholder,
            value,
            onFocus,
            onChange,
            onBlur,
            maxLength,
            readOnly
          }}
          className={classnames("form__input", {
            "form__input--shaded": isShaded,
            "form__input--caption": isCaptioned,
            "form__input--light": isLight,
            "form__input--disabled": isDisabled,
            "form__input--round": isRound
          })}
          required={isRequired}
          disabled={isDisabled}
          {...props}
        />
        {isIcon && (
          <Icon
            source={iconSource || Svg.Search}
            className={styles["form__input__icon"]}
          />
        )}
      </span>
    </FormField>
  );
};

export default FormInput;
