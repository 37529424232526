/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import React from "react";
import styles from "./Form.module.scss";
import FormField, { type FormFieldProps } from "./_FormField";

type Props = {
  name: string,
  placeholder?: string,
  value?: string,
  isDisabled?: boolean,
  isRequired?: boolean,
  isCaptioned?: boolean,
  isShaded?: boolean,
  isLight?: boolean,
  isRound?: boolean,
  /** Initial height can be specified (in rows) */
  rows?: number,
  /** Initial width can be specified (in columns) */
  cols?: number,
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void,
  maxLength?: number
} & FormFieldProps;

const FormTextArea = ({
  label,
  name,
  placeholder,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  isCaptioned = false,
  isShaded = false,
  isLight = false,
  isRound = false,
  rows,
  cols,
  onFocus,
  onChange,
  onBlur,
  maxLength
}: Props) => (
  <FormField
    {...{
      label,
      name,
      hint,
      error,
      isRequired,
      isDisabled,
      isErrorHidden
    }}
  >
    <span className={styles["form--input"]}>
      <textarea
        {...{
          name,
          placeholder,
          value,
          onFocus,
          onChange,
          onBlur,
          maxLength,
          rows,
          cols
        }}
        className={classnames("form__input", styles["text-area"], {
          "form__input--shaded": isShaded,
          "form__input--caption": isCaptioned,
          "form__input--light": isLight,
          "form__input--disabled": isDisabled,
          "form__input--round": isRound,
          [styles["text-area-cols"]]: cols
        })}
        required={isRequired}
        disabled={isDisabled}
      />
    </span>
  </FormField>
);

export default FormTextArea;
