/* @flow */

import classnames from "classnames";
import * as React from "react";
import PictureLoader, { type ResponsiveImageSet } from "../PictureLoader";

type Props = ResponsiveImageSet & {
  isFixed?: boolean,
  isCover?: boolean,
  className?: string,
  imageClassName?: string,
  children?: React.Node
};

type State = {
  backgroundImageSource: string
};

export default class Background extends React.Component<Props, State> {
  state = {
    backgroundImageSource: ""
  };

  _handlePictureSourceChange = (source: string) => {
    this.setState({
      backgroundImageSource: source
    });
  };

  render() {
    const {
      isFixed,
      isCover,
      images,
      imageBase,
      className,
      imageClassName,
      children
    } = this.props;

    const { backgroundImageSource } = this.state;

    return (
      <div
        className={classnames("background", className, {
          "background--fixed": isFixed,
          "background--cover": isCover
        })}
      >
        <PictureLoader
          isHidden
          images={images}
          imageBase={imageBase}
          altText=""
          onSourceLoad={this._handlePictureSourceChange}
        />
        <div
          className={classnames("background__image", imageClassName)}
          data-testid="background-image"
          style={{
            backgroundImage: `url(${encodeURI(
              backgroundImageSource || imageBase
            )})`
          }}
        />
        <noscript>
          <div
            className={classnames("background__image", imageClassName)}
            style={{
              backgroundImage: `url(${encodeURI(imageBase)})`
            }}
          />
        </noscript>
        {children}
      </div>
    );
  }
}
