/* @flow */

import classnames from "classnames";
import invariant from "invariant";
import * as React from "react";
import AccordionContext from "./_AccordionContext";
import AccordionItemContext from "./_AccordionItemContext";

type Props = {
  itemKey?: string,
  component: React.ElementType,
  className?: string,
  children?: React.Node | ((isActive: boolean) => React.Node)
};
type State = void;

const ControlledAccordionItem = ({
  component: Component,
  className = "",
  children,
  isBilling
}: {
  isActive: boolean,
  component: React.ElementType,
  className?: string,
  children?: React.Node,
  isBilling?: boolean
}) => (
  <Component
    className={classnames(
      { accordion__item: !isBilling, "bills-accordion__item": isBilling },
      className
    )}
  >
    {children}
  </Component>
);

const symbolIsAccordionItem = Symbol("isAccordionItem");

export default class AccordionItem extends React.Component<Props, State> {
  static defaultProps = { component: "div" };

  // $FlowFixMe: Flow doesn't support symbol properties
  static [symbolIsAccordionItem] = true;

  static isAccordionItem(component: React.ElementType) {
    return (
      component &&
      // $FlowFixMe: Flow doesn't support symbol properties
      component[symbolIsAccordionItem]
    );
  }

  render() {
    const { itemKey, className, children, component } = this.props;

    invariant(
      typeof itemKey === "string",
      "AccordionItem: Must either be the direct child of Accordion or have a unique string `itemKey`"
    );

    return (
      <AccordionContext.Consumer>
        {({ active, toggleActive, isBilling }) => (
          <AccordionItemContext.Provider
            value={{
              isActive: Boolean(active[itemKey]),
              toggleActive: () => toggleActive(itemKey),
              isBilling: isBilling
            }}
          >
            <ControlledAccordionItem
              isBilling={isBilling}
              isActive={active[itemKey]}
              component={component}
              className={className}
            >
              {typeof children === "function"
                ? children(Boolean(active[itemKey]))
                : children}
            </ControlledAccordionItem>
          </AccordionItemContext.Provider>
        )}
      </AccordionContext.Consumer>
    );
  }
}
