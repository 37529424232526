// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelectDropdown-module__no-boxmodel--1lXXh,.SelectDropdown-module__select-dropdown__list--3mr_9{padding:0;margin:0}.SelectDropdown-module__select-dropdown__container--32EMo{display:block}.SelectDropdown-module__select-dropdown__list--3mr_9{list-style:none;max-height:325px;overflow:auto;background-color:#fff}.SelectDropdown-module__select-dropdown__list-item--1pNz1{position:relative;padding:10px 20px;font-family:\"VodafoneRegular\";border:1px solid #999;border-bottom:1px solid #ccc;border-top:none;color:#333}.SelectDropdown-module__select-dropdown__list-item--1pNz1:focus-within{outline:2px solid #00b0ca}.SelectDropdown-module__select-dropdown__option--106sJ{display:flex;flex-direction:row;align-items:center}.SelectDropdown-module__select-dropdown__option--radio--1jLTv:hover{cursor:pointer}.SelectDropdown-module__select-dropdown__label--2QHNP{flex:1}.SelectDropdown-module__select-dropdown__title--3BuL5{font-size:18px;line-height:24px}.SelectDropdown-module__select-dropdown__subtitle--1olOR{font-size:16px;line-height:20px}.SelectDropdown-module__select-dropdown__radio--1H_pD{margin:0}\n", ""]);
// Exports
exports.locals = {
	"no-boxmodel": "SelectDropdown-module__no-boxmodel--1lXXh",
	"select-dropdown__list": "SelectDropdown-module__select-dropdown__list--3mr_9",
	"select-dropdown__container": "SelectDropdown-module__select-dropdown__container--32EMo",
	"select-dropdown__list-item": "SelectDropdown-module__select-dropdown__list-item--1pNz1",
	"select-dropdown__option": "SelectDropdown-module__select-dropdown__option--106sJ",
	"select-dropdown__option--radio": "SelectDropdown-module__select-dropdown__option--radio--1jLTv",
	"select-dropdown__label": "SelectDropdown-module__select-dropdown__label--2QHNP",
	"select-dropdown__title": "SelectDropdown-module__select-dropdown__title--3BuL5",
	"select-dropdown__subtitle": "SelectDropdown-module__select-dropdown__subtitle--1olOR",
	"select-dropdown__radio": "SelectDropdown-module__select-dropdown__radio--1H_pD"
};
module.exports = exports;
