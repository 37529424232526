/* @flow */

import classnames from "classnames";
import * as React from "react";
import styles from "./Heading.module.scss";

type Props = {
  children: React.Node,
  level: 1 | 2 | 3 | 4 | 5 | 6,
  component?: React.ElementType,
  fontWeight?: "light" | "regular" | "bold",
  isCenterAligned?: boolean,
  display?: "block" | "inline-block" | "inline",
  hasDarkBackground?: boolean,
  isLeading?: boolean,
  isTrailing?: boolean,
  hasNoGutter?: boolean,
  className?: string
};

const Heading = ({
  children,
  level,
  component,
  fontWeight = "",
  isCenterAligned,
  display = "block",
  hasDarkBackground = false,
  isLeading,
  isTrailing,
  hasNoGutter,
  className
}: Props) => {
  const MarkupComponent = component || `h${level}`;

  return (
    <MarkupComponent
      className={classnames("heading", `heading--${level}`, className, {
        [`heading--${fontWeight}`]: fontWeight,
        "heading--center": isCenterAligned,
        [`heading--${display}`]: display !== "block",
        "heading--leading": isLeading,
        "heading--trailing": isTrailing,
        "heading--no-gutter": hasNoGutter,
        [styles["heading--dark"]]: hasDarkBackground,
        "heading--dark": hasDarkBackground // Needed for liferay override
      })}
    >
      {children}
    </MarkupComponent>
  );
};

export default Heading;
