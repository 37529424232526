/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import uuidv4 from "uuid/v4";
import debounce from "lodash.debounce";
import Form from "../Form";
import OutsideClick from "../OutsideClick";
import styles from "./SelectMultiDropdown.module.scss";
import filterOptions from "./FilterOptions";
import { Option, SubOption } from "./Options";

export type optionObj = {
  title: string,
  id: string,
  icon?: string
};

export type optionProps = {
  title: string,
  id: string,
  icon?: string,
  groupOptions?: Array<optionObj>
};

type Props = {
  placeholder?: string,
  icon?: string,
  options: Array<optionProps>,
  isAlwaysOpen?: boolean,
  value?: string,
  isOpen?: boolean,
  iconName?: string,
  selectAll?: boolean,
  selected?: Array<optionObj>,
  selectAllLabelName?: string,
  onChange: (value: Object | Array<optionProps>) => void
};

const SelectMultiDropdown = ({
  placeholder,
  options,
  isAlwaysOpen = false,
  value = "",
  isOpen = isAlwaysOpen,
  iconName = "ChevronDown",
  selectAll = false,
  selected = [],
  selectAllLabelName = "Select all products",
  onChange,
  ...props
}: Props) => {
  const [query, setQuery] = useState("");
  const [matches, setMatches] = useState(options);
  const [isFilterOpen, setIsFilterOpen] = useState(isOpen);

  const handleFilter = debounce(() => {
    const $debounce = filterOptions(query, options);
    setMatches($debounce);
  }, 500);

  useEffect(() => {
    handleFilter();
  }, [query]);

  return (
    <div
      className={classNames(
        "select-dropdown",
        styles["select-dropdown__container"]
      )}
    >
      <OutsideClick
        handler={() => {
          setIsFilterOpen(false);
          setQuery("");
        }}
      >
        {/* $FlowFixMe: false negative */}
        <Form.Input
          {...{ placeholder }}
          value={query}
          iconSource={Svg[iconName]}
          isIcon
          onFocus={() => setIsFilterOpen(true)}
          onChange={(event: SyntheticEvent<HTMLInputElement>) =>
            setQuery(event.currentTarget.value)
          }
          {...props}
        />
        {isFilterOpen && (
          <div className={styles["select-multi-dropdown__dropdown"]}>
            <ul className={styles["select-multi-dropdown__list"]}>
              {selectAll && (
                <li
                  className={classNames(
                    styles["select-multi-dropdown--selectAll"]
                  )}
                >
                  <div
                    className={classNames(
                      styles["select-dropdown-group__option-selected"]
                    )}
                    onClick={() => onChange(options)}
                  >
                    {selectAllLabelName}
                  </div>
                </li>
              )}
              {matches.map(({ title, id, icon, groupOptions }) => (
                <Option
                  key={uuidv4()}
                  title={title}
                  id={id}
                  icon={icon}
                  isSelected={
                    selected.some(option => option.id === id) ||
                    (groupOptions &&
                      groupOptions.filter(
                        elem => !selected.find(({ id }) => elem.id === id)
                      ).length === 0)
                  }
                  onClick={() => {
                    onChange(options.filter(option => option.id === id)[0]);
                  }}
                >
                  {groupOptions &&
                    groupOptions.map(({ title, id, icon, ...otherProps }) => (
                      <SubOption
                        key={uuidv4()}
                        title={title}
                        id={id}
                        icon={icon}
                        isSelected={selected.some(option => option.id === id)}
                        onClick={() => onChange({ title, id, ...otherProps })}
                      />
                    ))}
                </Option>
              ))}
            </ul>
          </div>
        )}
      </OutsideClick>
    </div>
  );
};

export default SelectMultiDropdown;
