/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Icon from "../Icon";
import localStyles from "./SideChevron.module.scss";

type Props = {
  children: React.Node,
  chevronDirection: "up" | "right" | "down" | "left",
  className?: string,
  chevronContainerClassName?: string,
  contentClassName?: string,
  chevronClassName?: string,
  isInline?: boolean,
  hasWideText?: boolean,
  /** NOTE: **NOT** a CSS color value */
  chevronColor?: "white" | "red",
  isBilling?: boolean,
  hasBillingFlare?: boolean
};

const chevronIconMap = {
  up: Svg.ChevronUp,
  right: Svg.ChevronRight,
  down: Svg.ChevronDown,
  left: Svg.ChevronLeft
};

const SideChevron = ({
  children,
  chevronDirection,
  className,
  chevronContainerClassName,
  contentClassName,
  chevronClassName,
  isInline,
  hasWideText,
  chevronColor = "",
  isBilling,
  hasBillingFlare = true
}: Props) => {
  if (isBilling) {
    return (
      <>
        <div className={classnames({ "bills-summary": hasBillingFlare })}>
          {children}
        </div>
        <span className="bills-accordion__chevron">
          <Icon
            source={chevronIconMap[chevronDirection]}
            size="small"
            className={classnames("chevron__icon", chevronClassName, {
              [`chevron__icon--${chevronColor}`]: chevronColor
            })}
          />
        </span>
      </>
    );
  }
  const $chevron = (
    <span
      className={classnames(
        "chevron__container",
        localStyles["chevron__container"],
        chevronContainerClassName
      )}
    >
      <Icon
        source={chevronIconMap[chevronDirection]}
        size="small"
        className={classnames("chevron__icon", chevronClassName, {
          [`chevron__icon--${chevronColor}`]: chevronColor
        })}
      />
    </span>
  );
  const directedLeft = chevronDirection === "left";
  return (
    <span
      className={classnames(
        "chevron",
        {
          "chevron--inline": isInline,
          [localStyles["chevron--inline"]]: isInline
        },
        className
      )}
    >
      {directedLeft ? $chevron : null}
      <span
        className={classnames(
          "chevron__text",
          {
            "chevron__text--wide": hasWideText,
            [localStyles["chevron__text--push"]]: directedLeft
          },
          contentClassName
        )}
      >
        {children}
      </span>
      {!directedLeft ? $chevron : null}
    </span>
  );
};

export default SideChevron;
