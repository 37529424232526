/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import React, { type Node } from "react";
import DatePicker from "react-datepicker";
import "./DateTimePicker.external.scss";
import styles from "./DateTimePicker.module.scss";
import Icon from "../Icon";

const DateTimePicker = ({
  disabled,
  children,
  inputProps,
  ...props
}: {
  children?: Node,
  disabled?: boolean,
  inputProps?: any
}): Node => {
  const CustomInput = ({ className, ...props }: { className?: string }) => (
    <>
      <input
        {...props}
        {...inputProps}
        className={classnames("form__input", className, {
          "form__input--disabled": disabled
        })}
      />
      <Icon
        source={Svg.Calendar}
        className={classnames(styles["form__input__icon"], {
          [styles["form__input__icon--disabled"]]: disabled
        })}
      />
    </>
  );

  return (
    <DatePicker {...{ disabled }} customInput={<CustomInput />} {...props}>
      {children}
    </DatePicker>
  );
};

export default DateTimePicker;
