/* @flow */

import * as React from "react";

import AdvancedTableContext from "./_AdvancedTableContext";
import { Svg } from "@vf-dcl/vodafone-ws2";
import FlexTable from "../FlexTable";
import Table from "../Table";
import classnames from "classnames";
import styles from "./AdvancedTable.module.scss";

type Props = {
  getRowProps?: any,
  getCellProps?: any
};

const defaultPropGetter = () => ({});

const AdvancedTableBody = ({
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter
}: Props) => {
  const {
    layout,
    getTableBodyProps,
    page,
    prepareRow,
    renderRowSubComponent,
    resizeEnabled
  } = React.useContext(AdvancedTableContext);
  const MarkupComponent = layout === "flex" ? FlexTable : Table;

  return (
    <MarkupComponent.Body
      {...getTableBodyProps()}
      className={classnames({
        [styles["advanced-table__resizable-body"]]: resizeEnabled
      })}
    >
      {page.map(
        (row, index) =>
          prepareRow(row) || (
            <React.Fragment key={index}>
              <MarkupComponent.Row {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell, index) => (
                  <MarkupComponent.Data
                    key={index}
                    {...cell.getCellProps(getCellProps(cell))}
                  >
                    {cell.render("Cell")}
                  </MarkupComponent.Data>
                ))}
              </MarkupComponent.Row>
              {row.isExpanded && renderRowSubComponent({ row })}
            </React.Fragment>
          )
      )}
    </MarkupComponent.Body>
  );
};

export default AdvancedTableBody;
