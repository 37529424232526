/* @flow */
import * as React from "react";
import { components } from "react-select";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";

const ClearIndicator = (props: any) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <Icon source={Svg.Close} className="form__icon" />
      </components.ClearIndicator>
    )
  );
};

export default ClearIndicator;
