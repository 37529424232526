/* @flow */

import * as React from "react";

import Spring from "../Spring";
import classnames from "classnames";

type Props = {
  isCircle: boolean,
  children: React.Node,
  className?: string
};

const Tab = ({ isCircle, children, className }: Props) => (
  <div
    className={classnames(className, {
      "circle-tabs__content": isCircle,
      tabs__content: !isCircle
    })}
  >
    <Spring>{children}</Spring>
  </div>
);

export default Tab;
