/* @flow */

import * as React from "react";
import classnames from "classnames";
import DialCircle from "./_DialCircle";
import DialValue from "./_DialValue";
import DialCTA from "./_DialCTA";
import DialSubCTA from "./_DialSubCTA";
import localStyles from "./Dial.module.scss";

type Props = {
  children?: React.Node,
  /** Radius of the circle. A value lower or equal to zero disables rendering of the circle. */
  radius?: number,
  value: number,
  maxValue: number,
  /** If true, a small arrow is added to the dial. */
  dialIndicator?: boolean,
  color?:
    | "tangerine"
    | "limeade"
    | "finn"
    | "seance"
    | "cerulean"
    | "red"
    | "silver"
    | "amethyst",
  /** Shrinks the dial. */
  isTiny?: boolean,
  /** Draws attention to the value in the center of the dial by shrinking the inner padding and dial stroke. */
  isDashboard?: boolean,
  /** Increases the dial stroke width and applies the correct styling for displaying a call to action. */
  isBody?: boolean,
  /** Animates the dial from full down to its current value on first render. */
  hasInitialAnimation?: boolean,
  /** Any additional classnames passed to the button. */
  className?: string
};

const Dial = ({
  children,
  radius = 245,
  value,
  maxValue,
  dialIndicator,
  color = "red",
  isTiny,
  isDashboard,
  isBody,
  hasInitialAnimation = true,
  className
}: Props) => {
  const [currentValue, setCurrentValue] = React.useState(
    hasInitialAnimation ? maxValue : value
  );
  const percentageFilled = (currentValue / maxValue) * 100 * (radius / 80);
  const percentageStyle = `${percentageFilled}% 20000%`;
  const isFirstRender = React.useRef(true);

  // Animate the value of the dial on first render
  React.useEffect(() => {
    if (isFirstRender.current && hasInitialAnimation) {
      setTimeout(() => setCurrentValue(value), 1400);
      isFirstRender.current = false;
    } else {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <div
      className={classnames(
        "dial",
        "dial--full",
        `dial--${color}`,
        {
          "dial--tiny": isTiny,
          "dial--dashboard": isDashboard,
          "dial--body": isBody,
          [localStyles.dashboard]: isDashboard
        },
        className
      )}
    >
      {children}
      {dialIndicator && <span className="dial__indicator" />}
      <DialCircle radius={radius} percentageStyle={percentageStyle} />
    </div>
  );
};

Dial.CTA = DialCTA;

Dial.Value = DialValue;

Dial.SubCTA = DialSubCTA;

export default Dial;
