/* @flow */

import * as React from "react";

import Icon from "../Icon";
import TabItem from "./_Tabs";
import classnames from "classnames";

type props = {
  /** Uses Circles and Icons instead of the normal text tabs */
  isCircle?: boolean,
  /** Dark mode, give the normal accordion a dark background (Has no affect of the Circle Tabs) */
  isDark?: boolean,
  /** Removes the margins on each side of the tabs (take 100% of visible width). Only for Mobile */
  isStretched?: boolean,
  children: React.Node,
  defaultActive: string,
  /** A callback used when the tab is changed, it's called with the label of the selected tab */
  onChange?: string => void
};

type labelType = {
  label: string,
  icon: SVGImport
};

const SimpleTabs = ({
  isCircle = false,
  isDark = false,
  isStretched = false,
  children,
  defaultActive,
  onChange
}: props) => {
  const [activeTab: string, setActiveTab] = React.useState(defaultActive);

  const labelComponent = ({ label, icon }: labelType) => {
    if (isCircle) {
      return (
        <>
          <div className="circle-tabs__tab-circle">
            {!!icon && (
              <Icon className="circle-tabs__icon" size="small" source={icon} />
            )}
          </div>
          <div className="circle-tabs__tab-label">{label}</div>
        </>
      );
    }
    return label;
  };

  React.useEffect(() => {
    if (!activeTab) {
      setActiveTab(React.Children.map(children, child => child.props.label)[0]);
    }
  }, []);

  const firstRender = React.useRef(true);
  React.useEffect(() => {
    if (onChange && !firstRender.current) onChange(activeTab);
    firstRender.current = false;
  }, [activeTab]);

  return (
    <div
      className={classnames({
        tabs: !isCircle,
        "circle-tabs": isCircle,
        "tabs--dark": isDark,
        "tabs--stretch": isStretched
      })}
    >
      <div
        className={classnames({
          "tabs__navigation-wrapper": !isCircle,
          "circle-tabs__navigation-wrapper": isCircle
        })}
      >
        <nav
          className={classnames({
            tabs__navigation: !isCircle,
            "tabs__navigation--fixed": !isCircle,
            "circle-tabs__navigation": isCircle,
            "circle-tabs__navigation--gutter": isCircle
          })}
        >
          {React.Children.map(children, (child, index) => (
            <a
              key={`${child.props.label}_${index}`}
              tabIndex={index}
              onClick={() => setActiveTab(child.props.label)}
              className={classnames({
                tabs__tab: !isCircle,
                "circle-tabs__tab": isCircle,
                "tabs__tab--active":
                  child.props.label === activeTab && !isCircle,
                "circle-tabs__tab--active":
                  child.props.label === activeTab && isCircle
              })}
            >
              {labelComponent(child.props)}
            </a>
          ))}
        </nav>
      </div>
      {React.Children.map(
        children,
        child =>
          child.props.label === activeTab &&
          React.cloneElement(child, { isCircle: isCircle })
      )}
    </div>
  );
};

SimpleTabs.Tab = TabItem;

export default SimpleTabs;
