/* @flow */

import classnames from "classnames";
import * as React from "react";

type Props = {
  theme?: "silver" | "white" | "dustyGray",
  isSlim?: boolean,
  /** Only applies to mobile breakpoint. */
  isRuled?: boolean
};

const themeMap = {
  white: "white",
  dustyGray: "dusty-gray"
};

const Hr = ({ theme = "silver", isSlim, isRuled }: Props) => {
  return (
    <hr
      className={classnames("hr", {
        "hr--slim": isSlim,
        "hr--sm-ruled": isRuled,
        [`hr--${themeMap[theme]}`]: theme !== "silver"
      })}
    />
  );
};

export default Hr;
