// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Filter-module__filters--icon__counter--1MivK{padding-left:10px}.Filter-module__filters--icon__clear--258DT{margin-left:15px}.Filter-module__filters--icon__icon--2wHOw{stroke-width:1px;stroke:#333333}.Filter-module__filters--icon__icon--active--Z-U7z{stroke:#e60000}\n", ""]);
// Exports
exports.locals = {
	"filters--icon__counter": "Filter-module__filters--icon__counter--1MivK",
	"filters--icon__clear": "Filter-module__filters--icon__clear--258DT",
	"filters--icon__icon": "Filter-module__filters--icon__icon--2wHOw",
	"filters--icon__icon--active": "Filter-module__filters--icon__icon--active--Z-U7z"
};
module.exports = exports;
