/* @flow */
import * as React from "react";
import classnames from "classnames";
import Spring from "../Spring";
import Clickable from "../Clickable";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";
import styles from "./Pagination.module.scss";

type Props = {
  totalPages: number,
  currentPage: number,
  onNextPage: () => void,
  onPrevPage: () => void,
  children?: React.Node
};

type PaginationContextType = {
  totalPages: number,
  currentPage: number
};

export const PaginationContext = React.createContext<PaginationContextType>({});

const Pagination = ({
  totalPages,
  currentPage,
  onNextPage,
  onPrevPage,
  children
}: Props) => {
  return (
    <div className={classnames("pagination", styles["pagination__container"])}>
      <Spring>
        <Clickable
          onClick={onPrevPage}
          className={classnames("pagination__link", {
            "pagination__link--disabled": currentPage === 1
          })}
          disabled={currentPage === 1}
        >
          <span className="visually-hidden">Previous page</span>
          <Icon
            source={Svg.ChevronLeft}
            size="small"
            className="pagination__icon"
          />
        </Clickable>

        {children ? (
          <PaginationContext.Provider
            value={{
              totalPages,
              currentPage,
              onNextPage,
              onPrevPage
            }}
          >
            {children}
          </PaginationContext.Provider>
        ) : (
          <p className="no-gutter--top no-gutter--bottom pagination__info">
            <span className="pagination__current">{currentPage}</span>
            <span className="pagination__of">of</span>
            {totalPages}
          </p>
        )}

        <Clickable
          onClick={onNextPage}
          className={classnames("pagination__link", {
            "pagination__link--disabled": currentPage === totalPages
          })}
          disabled={currentPage === totalPages}
        >
          <span className="visually-hidden">Next page</span>
          <Icon
            source={Svg.ChevronRight}
            size="small"
            className="pagination__icon"
          />
        </Clickable>
      </Spring>
    </div>
  );
};

export default Pagination;
