/* @flow */

import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

type RouterLinkProps = React.ElementConfig<typeof Link>;

// Remove `className` and `children` from the data shape for a router link
type RouterLinkData = $Rest<
  RouterLinkProps,
  {| children: mixed, className: mixed |}
>;

type HtmlLinkData = { href: string };

export type ClickableAction =
  | void
  | { type?: "default" | "reset" }
  | { type: "submit", value?: string }
  | ({ type: "htmlLink" } & HtmlLinkData)
  | ({ type: "routerLink" } & RouterLinkData);

export type Props = ClickableAction & {
  className?: string,
  onClick?: (SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>) => void,
  children?: React.Node,
  stopPropagation?: boolean,
  // There's no Flow definition for all possible DOM props
  [key: string]: mixed
};

const Clickable = ({
  type,
  className,
  onClick,
  children,
  stopPropagation = false,
  ...rest
}: Props): React.Node => {
  let MarkupComponent, extraProps;

  switch (type) {
    case "htmlLink":
      MarkupComponent = "a";
      extraProps = {};
      break;
    case "routerLink":
      MarkupComponent = Link;
      extraProps = {
        // NOTE: When set to true it scrolls over the expected point.
        // Needs further investigation.
        smooth: false
      };
      break;
    case "reset":
      MarkupComponent = "button";
      extraProps = {
        type: "reset"
      };
      break;
    case "submit":
      MarkupComponent = "button";
      extraProps = {
        type: "submit"
      };
      break;
    default:
      MarkupComponent = "button";
      extraProps = {
        type: "button"
      };
      break;
  }

  return (
    <MarkupComponent
      className={className}
      onClick={event => {
        if (!onClick) {
          return;
        }
        if (stopPropagation) {
          event.stopPropagation();
        }
        return onClick(event);
      }}
      {...extraProps}
      {...rest}
    >
      {children}
    </MarkupComponent>
  );
};

export default Clickable;
