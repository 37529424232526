/* @flow */

import classnames from "classnames";
import "lazysizes";
import * as React from "react";

type Props = {
  alt: string,
  src: string,
  className?: string,
  lazyLoad?: boolean
};

const Image = ({ alt, src, className, lazyLoad }: Props) =>
  lazyLoad ? (
    <>
      <img
        data-src={src}
        alt={alt}
        className={classnames("lazyload", className)}
      />
      <noscript>
        <img src={src} alt={alt} className={className} />
      </noscript>
    </>
  ) : (
    <img src={src} alt={alt} className={className} />
  );

export default Image;
