/* @flow */
import * as React from "react";
import classnames from "classnames";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Clickable from "../Clickable";
import type { ClickableAction } from "../Clickable";
import styles from "./StatusTile.module.scss";

type Props = {
  title: string,
  description: string,
  number: number,
  action?: ClickableAction,
  onClick?: () => void,
  theme?: "red" | "tangerine" | "green" | "dove-gray" | "cerulean",
  isDisabled?: boolean,
  isSelected?: boolean
};

const StatusTile = ({
  title,
  description,
  number,
  action,
  onClick,
  theme = "dove-gray",
  isDisabled = false,
  isSelected = false
}: Props) => {
  const MarkupComponent =
    !isDisabled && (action || onClick) ? Clickable : "div";

  return (
    <MarkupComponent
      className={classnames(
        styles["status-tile"],
        styles[`status-tile--${theme}`],
        {
          [styles["status-tile__disabled"]]: isDisabled,
          [styles["status-tile__selected"]]: !isDisabled && isSelected
        }
      )}
      {...action}
      onClick={!isDisabled ? onClick : undefined}
    >
      <span className={styles["status-tile__number"]}>{number}</span>
      <div className={styles["status-tile__heading"]}>{title}</div>
      <div className={styles["status-tile__description"]}>{description}</div>
    </MarkupComponent>
  );
};

export default StatusTile;
