/* @flow */

import * as React from "react";
import Step from "./_Step";
import styles from "./Stepper.module.scss";

type Props = {
  children: React.Node,
  component?: React.ElementType
};

const Stepper = ({ children, component }: Props) => {
  const MarkupComponent = component || "div";
  return (
    <MarkupComponent className={styles["stepper"]}>{children}</MarkupComponent>
  );
};
Stepper.Step = Step;
export default Stepper;
