/* @flow */

import * as React from "react";

import Clickable from "../Clickable";
import { type FilterBodyProps } from "./types";
import FilterContext from "./_FilterContext";
import FilterGroupInput from "./_FilterGroupInput.web";
import FilterGroupSelect from "./_FilterGroupSelect.web";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";

const FilterBody = ({
  children,
  noBorder,
  extraChildrenTop,
  extraChildrenBottom
}: FilterBodyProps) => (
  <FilterContext.Consumer>
    {({ isOpen, toggleSidebar, filters }) => (
      <div
        className={classnames("filters__overflow", "gutter--top", {
          "filters__overflow--shift": isOpen
        })}
      >
        <div
          className={classnames("filters__sidebar", {
            "filters__sidebar--no-border": noBorder
          })}
        >
          <Clickable
            className="filters__close-button gutter--md-right"
            onClick={toggleSidebar}
          >
            <Icon source={Svg.Close} size="extra-small" />
            <span className="visually-hidden">Close</span>
          </Clickable>
          {extraChildrenTop}
          {filters.map((filter, index) => {
            if (
              filter.filterType === "radio" ||
              filter.filterType === "checkbox"
            )
              return <FilterGroupInput key={index} {...filter} />;
            else return <FilterGroupSelect key={index} {...filter} />;
          })}
          {extraChildrenBottom}
        </div>
        <div className="filters__search-results">
          <div className="filters__results-overlay" />
          {children}
        </div>
      </div>
    )}
  </FilterContext.Consumer>
);

export default FilterBody;
