/* @flow */

import * as React from "react";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Pagination from "../Pagination";
import AdvancedTableContext from "./_AdvancedTableContext";

type Props = {
  children?: React.Node
};

const AdvancedTablePagination = ({ children }: Props) => (
  <AdvancedTableContext.Consumer>
    {({ pageCount, pageIndex, nextPage, previousPage }) => (
      <Pagination
        totalPages={pageCount}
        currentPage={pageIndex + 1}
        onNextPage={nextPage}
        onPrevPage={previousPage}
      >
        {children}
      </Pagination>
    )}
  </AdvancedTableContext.Consumer>
);

export default AdvancedTablePagination;
