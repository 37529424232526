// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Overlay-module__overlay__close--2JdIS{display:inline-block !important;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"overlay__close": "Overlay-module__overlay__close--2JdIS"
};
module.exports = exports;
