/* @flow */

import * as React from "react";

import Icon from "../Icon";
import Link from "../Link";
import { Svg } from "@vf-dcl/vodafone-ws2";
import _SnackBarCookiePolicy from "./_SnackBarCookiePolicy.web";
import classnames from "classnames";
import styles from "./SnackBar.module.scss";

type Props = {
  /** Controls if the SnackBar should be visible or not */
  visible: boolean,
  /** The function called when the close button is clicked */
  onClose: () => {},
  children: React.Node,
  /** Text purposed for screen readers; it is part of the close button */
  accessibilityText?: string,
  /** Positions the snack bar relatively in the page */
  isRelative?: boolean,
  position?: "top" | "bottom",
  /** Any additional classes passed to the top most element of the component */
  containerClass?: string,
  /** Any additional classes passed to the close icon */
  iconClass?: string,
  /** The class that is applied to the component when it should be visible */
  visibleClass?: string
};

const SnackBar = ({
  children,
  visible,
  onClose,
  accessibilityText = "Close button",
  isRelative = false,
  position = "top",
  containerClass,
  iconClass,
  visibleClass = ""
}: Props) => (
  <div
    className={classnames(
      "nudge-slim",
      styles["snack-bar"],
      containerClass,
      isRelative
        ? styles["snack-bar--relative"]
        : [
            styles[`snack-bar--${position}`],
            visible ? styles[`snack-bar--visible-${position}`] : null
          ],
      { [visibleClass]: visible }
    )}
  >
    {children}
    <a className={classnames(styles["close"], iconClass)} onClick={onClose}>
      <Icon source={Svg.Close} size="small" className={"nudge-slim__icon"} />
      <span className="visually-hidden">{accessibilityText}</span>
    </a>
  </div>
);

SnackBar.CookiePolicy = _SnackBarCookiePolicy;

export default SnackBar;
