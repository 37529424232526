// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Carousel-module__carousel__slide--middle--1obAu{vertical-align:middle;text-align:center}.Carousel-module__carousel__slider--animation--g70rL{transition:transform 1s}\n", ""]);
// Exports
exports.locals = {
	"carousel__slide--middle": "Carousel-module__carousel__slide--middle--1obAu",
	"carousel__slider--animation": "Carousel-module__carousel__slider--animation--g70rL"
};
module.exports = exports;
