import * as React from "react";
import Link from "../Link";

type _SnackBarCookiePolicyProps = {
  link: string
};

const _SnackBarCookiePolicy = ({ link }: _SnackBarCookiePolicyProps) => (
  <>
    By using this website you are agreeing to the vodafone{" "}
    <Link isBody action={{ href: link, type: "htmlLink" }}>
      cookie policy
    </Link>
  </>
);

export default _SnackBarCookiePolicy;
