/* @flow */
import * as React from "react";
import classnames from "classnames";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";

type Props = {
  /** The tag text. */
  label: string,
  /** The color of the tag. */
  style?: "seance" | "lagoon" | "green" | "red" | "guardsman-red",
  /** Border styling */
  hasNoBorder?: boolean,
  /** Specifies CSS classes to be added to the root .tag container. */
  classes?: string,
  /** Specificies if the tag has icon */
  hasIcon?: boolean,
  /** Specifies the icon to use. Defaults to 'Recommendation' */
  iconSource?: SVGImport,
  /** Specifies the accesibility text */
  description?: string
};

const Tag = ({
  label,
  style = "green",
  classes,
  iconSource,
  description = "Item highlighted",
  hasNoBorder = false,
  hasIcon = false
}: Props) => {
  return (
    <p
      className={classnames(
        "tag",
        `tag--${style}`,
        { "tag--no-border": hasNoBorder },
        classes
      )}
    >
      <span className="visually-hidden">{description}</span>
      <span className="tag__label">
        {hasIcon && (
          <Icon
            source={iconSource || Svg.Recommendation}
            size="extra-small"
            className="tag__icon"
          />
        )}
        {label}
      </span>
    </p>
  );
};

export default Tag;
