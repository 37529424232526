/* @flow */

import * as React from "react";

type Props = {
  handler: () => void,
  children: React.Node,
  isDisabled?: boolean
};

const OutsideClick = ({
  handler,
  children,
  isDisabled = false,
  ...props
}: Props) => {
  let ref = React.useRef(null);

  const detectOutsideClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target) && !isDisabled) {
      handler();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", detectOutsideClick);
    return () => {
      document.removeEventListener("mousedown", detectOutsideClick);
    };
  });

  return (
    // $FlowFixMe
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};

export default OutsideClick;
