import classnames from "classnames";
import { Dot, WithStore } from "pure-react-carousel";
import * as React from "react";

type Props = {
  visibleSlides: number,
  totalSlides: number,
  currentSlide: number
};

const CarouselPagination = React.forwardRef(
  ({ visibleSlides, totalSlides, currentSlide }: Props, ref) => {
    let pageCount = totalSlides - visibleSlides + 1;
    if (pageCount < 1) {
      pageCount = 1;
    }

    return (
      <ul className="carousel__pagination carousel__pagination--visible">
        {[...Array(pageCount)].map((_, index) => (
          <CarouselPage
            key={index}
            slideNumber={index}
            isActive={index === currentSlide}
          />
        ))}
      </ul>
    );
  }
);

type PageProps = {
  slideNumber: number,
  isActive: boolean
};

const CarouselPage = ({ slideNumber, isActive }: PageProps) => {
  return (
    <li className="carousel__page-item">
      <Dot
        slide={slideNumber}
        disabled={false}
        className={classnames("button", "button--reset", "carousel__page", {
          "carousel__page--active": isActive
        })}
      >
        <span className="visually-hidden">{`go to item ${slideNumber}`}</span>
      </Dot>
    </li>
  );
};

export default WithStore(CarouselPagination, state => ({
  visibleSlides: state.visibleSlides,
  totalSlides: state.totalSlides,
  currentSlide: state.currentSlide
}));
