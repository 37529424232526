/* @flow */

import classnames from "classnames";
import * as React from "react";

type Props = {
  children: React.Node,
  isInline?: boolean,
  spaceTop?: "default" | "leading" | "slim" | "medium" | "large"
};

const FormRow = ({ children, isInline, spaceTop = "default" }: Props) => {
  return (
    <div
      className={classnames("form__row", {
        [`form__row--${spaceTop}`]: spaceTop !== "default",
        "form__row--inline": isInline
      })}
    >
      {children}
    </div>
  );
};

export default FormRow;
