// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Step-module__step--3q0C7{display:flex;flex-direction:column;color:#333;text-align:center;align-items:center;z-index:1;margin-right:auto}.Step-module__step--3q0C7:first-child{margin-left:0;align-items:flex-start}.Step-module__step--3q0C7:last-child{margin-right:0;align-items:flex-end}.Step-module__step__title--L5Gts{visibility:hidden;margin-bottom:5px}.Step-module__step__dot--2wOAO{border-radius:50%;width:50px;height:50px;padding-top:4px;background:#ebebeb}.Step-module__step__dot--2wOAO>*{pointer-events:none}.Step-module__step__dot--2wOAO::before{content:\"\";display:inline-block;height:100%;vertical-align:middle}.Step-module__step--active--3tQhU .Step-module__step__title--L5Gts{visibility:visible}.Step-module__step--active--3tQhU .Step-module__step__dot--2wOAO{background:#e60000;color:#fff}.Step-module__step--completed--2fi53 .Step-module__step__dot--2wOAO{background:#428600;color:#fff;padding-top:0}.Step-module__step--warning--2VYLl .Step-module__step__dot--2wOAO{background:#fecb00;color:#fff;padding-top:0}.Step-module__step--inactive--1EiYK:hover .Step-module__step__title--L5Gts{visibility:visible}\n", ""]);
// Exports
exports.locals = {
	"step": "Step-module__step--3q0C7",
	"step__title": "Step-module__step__title--L5Gts",
	"step__dot": "Step-module__step__dot--2wOAO",
	"step--active": "Step-module__step--active--3tQhU",
	"step--completed": "Step-module__step--completed--2fi53",
	"step--warning": "Step-module__step--warning--2VYLl",
	"step--inactive": "Step-module__step--inactive--1EiYK"
};
module.exports = exports;
