/* @flow */

import * as React from "react";
import Button from "../Button";

type Props = {
  /** The label passed to the first button */
  firstLabel: string,
  /** The label passed to the second button */
  secondLabel: string,
  /** The function called when the state is changed */
  onChange: (boolean | void) => void,
  /** The function called when the first button is pressed */
  onActivate?: () => void,
  /** The function called when the second button is pressed */
  onDeactivate?: () => void,
  /** The props passed to the first button */
  firstButtonProps?: {},
  /** The props passed to the second button */
  secondButtonProps?: {},
  /** The class name passed to the parent <span></span> */
  className?: string,
  /** Disables the buttons */
  isDisabled?: boolean,
  /** Defines the initial state of the component */
  initialState?: boolean,
  /** Defines the colour of the active button */
  color?: "grey" | "red" | "green",
  /** Give the button the ability to switch between having one active or neither active */
  canToggleActive?: boolean
};

const ToggleButton = ({
  firstLabel,
  secondLabel,
  onChange,
  onActivate,
  onDeactivate,
  firstButtonProps,
  secondButtonProps,
  className,
  isDisabled = false,
  initialState,
  color = "grey",
  canToggleActive = false
}: Props) => {
  const [active, toggleActive] = React.useState(initialState);

  let firstRender = React.useRef(true);
  React.useEffect(() => {
    if (onChange && !firstRender.current) onChange(active);
    firstRender.current = false;
  }, [active]);

  const onActivateHandler = () => {
    if (onActivate) onActivate();
    toggleActive(canToggleActive && active ? undefined : true);
  };

  const onDeactivateHandler = () => {
    if (onDeactivate) onDeactivate();
    toggleActive(canToggleActive && active === false ? undefined : false);
  };

  return (
    <span className={className}>
      <Button
        {...firstButtonProps}
        disabled={isDisabled}
        rank={color === "red" ? "primary" : "secondary"}
        selected={color === "green" && active}
        transparent={!active || active === undefined}
        onClick={onActivateHandler}
      >
        {firstLabel}
      </Button>
      <Button
        {...secondButtonProps}
        disabled={isDisabled}
        rank={color === "red" ? "primary" : "secondary"}
        selected={color === "green" && !active}
        transparent={active || active === undefined}
        onClick={onDeactivateHandler}
      >
        {secondLabel}
      </Button>
    </span>
  );
};

export default ToggleButton;
