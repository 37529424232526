/* @flow */

import classnames from "classnames";
import * as React from "react";
import SideChevron from "../SideChevron";
import localStyles from "./Accordion.module.scss";
import AccordionItemContext from "./_AccordionItemContext";

type Props = {
  children: React.Node,
  component?: React.ElementType,
  className?: string,
  chevronClassName?: string,
  chevronWrapperClassName?: string,
  chevronColor?: "white" | "red",
  hasBillingFlare?: boolean,
  onToggle?: () => void
};

const AccordionHeading = ({
  isActive,
  component: Component,
  className,
  chevronWrapperClassName,
  chevronClassName,
  chevronColor,
  children,
  hasBillingFlare,
  onToggle
}: Props) => {
  const { toggleActive } = React.useContext(AccordionItemContext);

  const _handleToggle = () => {
    toggleActive();
    if (onToggle) onToggle();
  };

  const _handleKeyDown = event => {
    if (event.keyCode === 13 /* enter */) {
      _handleToggle();
    }
  };

  const _handleClick = event => {
    _handleToggle();
  };

  const MarkupComponent = Component || "h3";

  return (
    <AccordionItemContext.Consumer>
      {({ isActive, toggleActive, isBilling }) => (
        <MarkupComponent
          className={classnames(
            {
              accordion__heading: !isBilling,
              "accordion__heading--active": isActive && !isBilling,
              "bills-accordion__heading": isBilling,
              "bills-accordion__heading--active": isActive && isBilling
            },
            className
          )}
          tabIndex={0}
          role="tab"
          aria-selected={isActive}
          aria-expanded={isActive}
          onKeyDown={_handleKeyDown}
          onClick={_handleClick}
        >
          <SideChevron
            isBilling={isBilling}
            hasBillingFlare={hasBillingFlare}
            className={chevronWrapperClassName}
            chevronDirection="down"
            chevronContainerClassName={classnames({
              accordion__chevron: !isBilling,
              "bills-accordion__chevron": isBilling
            })}
            chevronClassName={chevronClassName}
            chevronColor={chevronColor}
          >
            {children}
          </SideChevron>
        </MarkupComponent>
      )}
    </AccordionItemContext.Consumer>
  );
};

export default AccordionHeading;
