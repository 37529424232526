/* @flow */

import * as React from "react";

const FilterContext = React.createContext<any>({
  isOpen: false,
  toggleSidebar: () => {},
  filters: [],
  activeFilters: [],
  setActiveFilters: () => {},
  clearFilters: () => {}
});

export default FilterContext;
