// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Pagination-module__pagination__container--2fD3m{width:100%;padding:40px 0}\n", ""]);
// Exports
exports.locals = {
	"pagination__container": "Pagination-module__pagination__container--2fD3m"
};
module.exports = exports;
