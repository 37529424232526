/* @flow */

import * as React from "react";

import { LanguageSection, Tab, TopBar, TypeSection } from "./_TopBar";

import LanguageDropDown from "./_LanguageDropDown";
import NavDropDown from "./_NavigationDropDown.web";
import NavIcon from "./_NavigationIcon";
import NavItem from "./_NavItem";
import SideBar from "./_SideBar";
import Spring from "../Spring";
import classnames from "classnames";

type Props = {
  children: React.Node,
  className?: string
};

const Header = ({ children, className, ...props }: Props) => (
  <nav className={classnames("header", className)} {...props}>
    {children}
  </nav>
);

Header.NavBar = ({ children, className, ...props }: Props) => (
  <div
    className={classnames("navigation navigation--primary", className)}
    {...props}
  >
    <Spring>
      <ul className="navigation__list navigation__spring" role="navigation">
        {children}
      </ul>
    </Spring>
  </div>
);

Header.NavItem = NavItem;
Header.NavIcon = NavIcon;
Header.NavDropDown = NavDropDown;

Header.TopBar = TopBar;
Header.LanguageSection = LanguageSection;
Header.TypeSection = TypeSection;
Header.Tab = Tab;
Header.LanguageDropDown = LanguageDropDown;

Header.SideBar = SideBar;
export default Header;
