/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classNames from "classnames";
import pick from "lodash.pick";
import React, { type Node, Children, cloneElement } from "react";
import Icon from "../Icon";
import FormField, { type FormFieldProps } from "./_FormField";
import FormToggleButton, {
  type Props as FormToggleButtonProps
} from "./_FormToggleButton";
import styles from "./Form.module.scss";

type Props = {
  accent?: "grey" | "red" | "green",
  label: string,
  name: string,
  value?: string,
  children: Node,
  isDisabled?: boolean,
  isCaptioned?: boolean,
  darkBackground?: boolean,
  onFocus?: $PropertyType<FormToggleButtonProps, "onFocus">,
  onChange: $PropertyType<FormToggleButtonProps, "onChange">,
  onBlur?: $PropertyType<FormToggleButtonProps, "onBlur">
} & FormFieldProps;

const FormToggle = ({
  accent = "grey",
  label,
  name,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  isCaptioned = false,
  darkBackground = false,
  children,
  ...props
}: Props) => {
  return (
    <FormField
      {...{
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden
      }}
      type="group"
    >
      <div className={styles["form__toggle"]}>
        {Children.map(children, child =>
          cloneElement(child, {
            ...child.props,
            ...pick(props, ["onChange", "onFocus", "onBlur"]),
            accent,
            name,
            isRequired,
            isDisabled,
            darkBackground,
            isChecked: child.props.value === value
          })
        )}
      </div>
    </FormField>
  );
};

export default FormToggle;
