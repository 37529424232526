/* @flow */

import * as React from "react";
import styles from "./Box.module.scss";

const Box = ({ children }: { children: React.Node }) => (
  <div className={styles["box"]}>{children}</div>
);

export default Box;
