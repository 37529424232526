/* @flow */

import * as React from "react";
import classNames from "classnames";
import uuidv4 from "uuid/v4";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";
import styles from "./SelectMultiDropdown.module.scss";
import type { optionProps, optionObj } from "./SelectMultiDropdown";

type Props = {
  title: string,
  id: string,
  icon?: string,
  isSelected?: boolean,
  children?: React.Node,
  onClick: (value: Array<string>) => void
};

export const Option = ({
  title,
  id,
  icon,
  isSelected,
  onClick,
  children
}: Props) => (
  <li key={uuidv4()} className={styles["select-multi-dropdown__list-item"]}>
    <div
      className={classNames(
        styles["select-multi-dropdown__option"],
        styles["select-multi-dropdown__option--radio"],
        {
          "select-multi-dropdown__option--active": isSelected
        }
      )}
    >
      <div className={styles["select-multi-dropdown__label"]}>
        <div className={styles["select-multi-dropdown__dropdown"]}>
          <input type="checkbox" defaultChecked={isSelected} />
          <div
            className={classNames(
              styles["select-multi-dropdown__label"],
              styles["select-multi-dropdown__label--name"]
            )}
            onClick={onClick}
          >
            {icon && (
              <Icon
                source={Svg[icon]}
                size="extra-small"
                className={styles["form__input__icon"]}
              />
            )}
            {title}
          </div>
          {children}
        </div>
      </div>
    </div>
  </li>
);

export const SubOption = ({ title, id, icon, isSelected, onClick }: Props) => (
  <div
    className={classNames(styles["select-multi-dropdown__group"])}
    key={uuidv4()}
  >
    <input type="checkbox" defaultChecked={isSelected} />
    <div
      key={uuidv4()}
      className={styles["select-multi-dropdown__group-list-item"]}
      onClick={onClick}
    >
      {icon && (
        <Icon
          source={Svg[icon]}
          size="extra-small"
          className={styles["form__input__icon"]}
        />
      )}
      {title}
    </div>
  </div>
);
