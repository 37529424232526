/* @flow */

import * as React from "react";
import _Bubble from "./_BubbleContainer";

type Props = {
  /** The message of the bubble. */
  children: React.Node,
  /** The title of the bubble. */
  title?: React.Node,
  /** Invert the colors of the bubble – dark text on light background. */
  isLight?: boolean,
  /** Specify if this bubble has new visuals – red line beside text. */
  isNew?: boolean,
  /** Flips the bubble, so the tail is on the right side */
  isRight?: boolean,
  /** Changes the titles to align with the chat bubble pattern */
  isChat?: string | boolean,
  /** Specifies CSS classes to be added to the root .bubble container.  */
  className?: string
};

const Bubble = ({
  title,
  children,
  isLight = false,
  isNew = false,
  isRight = false,
  isChat,
  className
}: Props) => {
  return (
    <_Bubble
      isLight={isLight}
      isNew={isNew}
      isRight={isRight}
      isChat={isChat}
      className={className}
    >
      {!isChat && <h4 className="bubble__title">{title}</h4>}
      {isChat && <span className="bubble__user">{title}</span>}
      <p className="bubble__message">{children}</p>
    </_Bubble>
  );
};

type TypingProps = { isChat?: boolean, className?: string };

const Typing = ({ className, isChat }: TypingProps) => {
  return (
    <_Bubble isTyping isChat={isChat} className={className}>
      <span className="bubble__dot" />
      <span className="bubble__dot bubble__dot--middle" />
      <span className="bubble__dot bubble__dot--last" />
    </_Bubble>
  );
};

Bubble.Typing = Typing;
export default Bubble;
