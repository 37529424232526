/* @flow */

import * as React from "react";

import Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Icon from "../Icon";
import MobileBackend from "react-dnd-touch-backend";
import OutsideClick from "../OutsideClick";
import { Svg } from "@vf-dcl/vodafone-ws2";
import _DropDownContainer from "./_DropDownContainer.web";
import _DropDownOption from "./_DropDownOption.web";
import classnames from "classnames";
import styles from "./SortableDropDown.module.scss";

type Props = {
  /** Applies a top margin to allow for label */
  isCaptioned?: boolean,
  /** Gives it a light theme with no border */
  isLight?: boolean,
  /** Stops the user from opening the drop down */
  isDisabled?: boolean,
  /** The options rendered in the drop down, must be a <SortableDropDown.Option/>  */
  children: React.ChildrenArray<React.Element<typeof _DropDownOption>>,
  /** The onChange called with the array of selected children's labels */
  onChange: (Array<string>) => void,
  /** Enables the user to use touch controllers */
  useTouch?: boolean
};
const SortableDropDown = ({
  isCaptioned,
  isLight,
  isDisabled,
  children,
  onChange,
  useTouch
}: Props) => {
  const selectedChildren = React.Children.toArray(children)
    .filter(child => child.props.isChecked)
    .map(child => child.props.label);

  const [isOpen, changeOpenState] = React.useState(false);
  const [sortedChildren, sortChildren] = React.useState(
    React.Children.toArray(children)
  );
  const [selectedOptions, updateSelectedOptions] = React.useState(
    selectedChildren
  );

  //Calls the onChange every time the selected options are updated, bar the first render.
  const firstRender = React.useRef(true);
  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (onChange) onChange(selectedOptions);
  }, [selectedOptions]);

  // Updates the array of selected options with the label of that option. If it's in the array, remove it. If it's not, add it (but in the correct order).
  const onChangeHandler = (label: string) => {
    if (!selectedOptions.includes(label)) {
      updateSelectedOptions([
        ...sortedChildren
          .filter(child =>
            [...selectedOptions, label].includes(child.props.label)
          )
          .map(selectedChild => selectedChild.props.label)
      ]);
    } else {
      updateSelectedOptions(selectedOptions.filter(a => a !== label));
    }
  };

  // Used by the "Default View" button to set the order of the options and whether they are selected back to the way they started.
  const setDefaultHandler = () => {
    updateSelectedOptions(selectedChildren);
    sortChildren(React.Children.toArray(children));
  };

  const moveCard = React.useCallback((dragIndex, hoverIndex) => {
    let tempArray = [...sortedChildren];
    tempArray.splice(dragIndex, 1);
    tempArray.splice(hoverIndex, 0, sortedChildren[dragIndex]);
    updateSelectedOptions(
      tempArray
        .filter(child => selectedOptions.includes(child.props.label))
        .map(child => child.props.label)
    );
    sortChildren(tempArray);
  });

  return (
    <OutsideClick
      style={{ position: "relative", width: "100%" }}
      isDisabled={!isOpen}
      handler={() => changeOpenState(false)}
    >
      <div
        className={classnames("form__input", "form__input--selectable", {
          "form__input--caption": isCaptioned,
          "form__input--light": isLight,
          "form__input--disabled": isDisabled
        })}
      >
        <span
          onClick={() => {
            if (!isDisabled) changeOpenState(!isOpen);
          }}
          className="form__select"
        >
          Choose Column
        </span>
        <Icon source={Svg.ChevronDown} className="form__icon" />
      </div>
      {isOpen && !isDisabled && (
        <DndProvider backend={useTouch ? MobileBackend : Backend}>
          <_DropDownContainer
            onChange={onChangeHandler}
            selectedOptions={selectedOptions}
            setDefaultHandler={setDefaultHandler}
            isLight={isLight}
            moveCard={moveCard}
          >
            {sortedChildren}
          </_DropDownContainer>
        </DndProvider>
      )}
    </OutsideClick>
  );
};

SortableDropDown.Option = _DropDownOption;
export default SortableDropDown;
