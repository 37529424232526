// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dial-module__icon--3gxud{left:52%}.Dial-module__dashboard--XOi4C{width:180px}\n", ""]);
// Exports
exports.locals = {
	"icon": "Dial-module__icon--3gxud",
	"dashboard": "Dial-module__dashboard--XOi4C"
};
module.exports = exports;
