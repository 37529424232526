/* @flow */

import * as React from "react";

import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";

type Props = {
  row: any
};

const AdvancedTableExpandCell = ({ row }: Props) => (
  <span
    style={{ textAlign: "center", margin: "auto", width: "30px" }}
    {...row.getExpandedToggleProps()}
  >
    <div>
      {row.isExpanded ? (
        <Icon source={Svg.ChevronUp} size="extra-small" />
      ) : (
        <Icon source={Svg.ChevronDown} size="extra-small" />
      )}
    </div>
  </span>
);

export default AdvancedTableExpandCell;
