// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FlexTable-module__table--2mxtP{display:block;overflow:auto}.FlexTable-module__table--2mxtP .FlexTable-module__thead--yv-JM{overflow-y:auto;overflow-x:auto}.FlexTable-module__table--2mxtP .FlexTable-module__tbody--1TO9n{overflow-y:auto;overflow-x:auto}.FlexTable-module__table--2mxtP .FlexTable-module__tr--2hC3L{display:flex;flex:1 0 auto}.FlexTable-module__table--2mxtP .FlexTable-module__tr--2hC3L :last-child .FlexTable-module__td--2Gj57{border-bottom:0}.FlexTable-module__table--2mxtP .FlexTable-module__th--2L1_K,.FlexTable-module__table--2mxtP .FlexTable-module__td--2Gj57{position:relative;justify-content:flex-start;align-items:flex-start;display:flex;width:200px}.FlexTable-module__table--2mxtP .FlexTable-module__th--2L1_K.FlexTable-module__align-right--82yuh,.FlexTable-module__table--2mxtP .FlexTable-module__td--2Gj57.FlexTable-module__align-right--82yuh{justify-content:flex-end}\n", ""]);
// Exports
exports.locals = {
	"table": "FlexTable-module__table--2mxtP",
	"thead": "FlexTable-module__thead--yv-JM",
	"tbody": "FlexTable-module__tbody--1TO9n",
	"tr": "FlexTable-module__tr--2hC3L",
	"td": "FlexTable-module__td--2Gj57",
	"th": "FlexTable-module__th--2L1_K",
	"align-right": "FlexTable-module__align-right--82yuh"
};
module.exports = exports;
