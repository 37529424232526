// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".List-module__list__item--lined--2tYVb:last-child{border-bottom:none}.List-module__list__item--rounded--H9zfi{padding:12px 20px;border:1px solid #f4f4f4;border-radius:18px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:#666;font-weight:normal;margin-bottom:20px}\n", ""]);
// Exports
exports.locals = {
	"list__item--lined": "List-module__list__item--lined--2tYVb",
	"list__item--rounded": "List-module__list__item--rounded--H9zfi"
};
module.exports = exports;
