/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import changeCase from "change-case";
import classnames from "classnames";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import pluralize from "pluralize";
import React, {
  type Node,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import uuidv4 from "uuid/v4";
import Alert from "../Alert";
import Button from "../Button";
import Heading from "../Heading";
import Link from "../Link";
import List from "../List";
import Section from "../Section";
import Spring from "../Spring";
import FormDateTimePicker from "./_FormDateTimePicker";
import { getFormLabelSelector } from "./_FormField";
import FormInput from "./_FormInput";
import FormTextArea from "./_FormTextArea";
import FormToggle from "./_FormToggle";
import FormToggleButton from "./_FormToggleButton";
import FormRow from "./_FormRow";
import FormSelect from "./_FormSelect";
import FormSelectWithSearch from "./_FormSelectWithSearch";

type Props = $ReadOnly<{|
  id?: string,
  errors?: $ReadOnly<{ [name: string]: string }>,
  submitButtonLabel?: string,
  isDark?: boolean,
  children: Node,
  onSubmit?: (event: SyntheticEvent<HTMLFormElement>) => void
|}>;

const Form = ({
  id,
  errors = {},
  submitButtonLabel,
  isDark = false,
  children,
  onSubmit
}: Props) => {
  const form = useRef();
  const [errorLabels, setErrorLabels] = useState({});
  useEffect(() => {
    if (form.current && !isEmpty(errors)) {
      form.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [form, errors]);
  useEffect(() => {
    if (form.current) {
      const $form = form.current;
      const nextErrorLabels = Object.entries(errors).reduce(
        (labels, [name]) => {
          const label = $form.querySelector(getFormLabelSelector(name))
            ?.textContent;
          if (!label) {
            return labels;
          }
          return {
            ...labels,
            [name]: label
          };
        },
        {}
      );
      if (!isEqual(errorLabels, nextErrorLabels)) {
        setErrorLabels(nextErrorLabels);
      }
    }
  }, [form, errors, errorLabels]);
  const handleErrorClick = useCallback(
    (name: string) => {
      if (form.current) {
        const $form = form.current;
        $form
          .querySelector(getFormLabelSelector(name))
          .scrollIntoView({ behavior: "smooth" });
      }
    },
    [form]
  );
  return (
    <form
      {...{ id }}
      // $FlowFixMe: false negative
      ref={form}
      className={classnames(
        "form",
        { "form--dark": isDark },
        "no-gutter--bottom"
      )}
      noValidate
      onSubmit={(e: SyntheticEvent<HTMLFormElement>) => {
        if (onSubmit) {
          e.preventDefault();
          onSubmit(e);
        }
      }}
    >
      {!isEmpty(errorLabels) ? (
        <Section color="wild-sand" isTrailing>
          <Spring>
            <Alert iconSource={Svg.Block} isLight>
              <Heading level={6} component="h3" fontWeight="regular" isLeading>
                {(() => {
                  const count = Object.keys(errorLabels).length;
                  return `Sorry, there ${pluralize("were", count)} ${pluralize(
                    "error",
                    count,
                    true
                  )} in the
              following ${pluralize("field", count)}:`;
                })()}
              </Heading>
              <List component="ol">
                {Object.entries(errorLabels).map(([name, label]) => (
                  <List.Item key={uuidv4()}>
                    <Link isBody onClick={() => handleErrorClick(name)}>
                      {changeCase.sentence(label)}
                    </Link>
                  </List.Item>
                ))}
              </List>
            </Alert>
          </Spring>
        </Section>
      ) : null}
      {children}
      {submitButtonLabel ? (
        <Form.Row>
          <Button type="submit" disabled={isEmpty(errors)}>
            {submitButtonLabel}
          </Button>
        </Form.Row>
      ) : null}
    </form>
  );
};
Form.Row = FormRow;
Form.Input = FormInput;
Form.Select = FormSelect;
Form.FormSelectWithSearch = FormSelectWithSearch;
Form.TextArea = FormTextArea;
Form.DateTimePicker = FormDateTimePicker;
Form.Toggle = FormToggle;
Form.ToggleButton = FormToggleButton;

export default Form;
