/* @flow */

import classnames from "classnames";
import * as React from "react";
import styles from "./List.module.scss";

type Props = {
  children: React.ChildrenArray<React.Element<typeof ListItem>>,
  className?: string,
  isReset?: boolean,
  hasNoStyle?: boolean,
  hasOverflow?: boolean,
  component?: "ul" | "ol"
};

type ItemProps = {
  children: React.Node,
  className?: string,
  isLined?: boolean,
  isRounded?: boolean
};

const ListItem = ({ children, className, isLined, isRounded }: ItemProps) => {
  return (
    <li
      className={classnames("list__item", className, {
        "list__item--lined": isLined,
        [styles["list__item--lined"]]: isLined,
        [styles["list__item--rounded"]]: isRounded
      })}
    >
      {children}
    </li>
  );
};

export default class List extends React.Component<Props> {
  static Item = ListItem;

  render() {
    const {
      children,
      className,
      isReset,
      hasNoStyle,
      hasOverflow,
      component: MarkupComponent = "ul"
    } = this.props;

    return (
      <MarkupComponent
        className={classnames("list", className, styles["list"], {
          "list--reset": isReset,
          "list--no-style": hasNoStyle,
          "list--overflow": hasOverflow
        })}
      >
        {children}
      </MarkupComponent>
    );
  }
}
