/* @flow */

import classnames from "classnames";
import * as React from "react";

type Props = {
  children: React.Node,
  component?: React.ElementType,
  isWide?: boolean,
  size?: "small" | "medium" | "large",
  className?: string
};

const warned = {
  wide: false,
  size: false
};

const SpringNestingContext = React.createContext<number>(0);

const Spring = ({
  children,
  component,
  isWide,
  size = "large",
  className
}: Props) => {
  const MarkupComponent = component || "div";

  return (
    <SpringNestingContext.Consumer>
      {nested => {
        /* istanbul ignore next */
        if (isWide && !nested && !warned.wide) {
          warned.wide = true;
          // eslint-disable-next-line no-console
          console.warn(
            "Spring: The 'wide' prop has no effect on the outermost Spring element."
          );
        }

        /* istanbul ignore next */
        if (size && size !== "large" && nested && !warned.size) {
          warned.size = true;
          // eslint-disable-next-line no-console
          console.warn(
            "Spring: The 'size' prop is only supported on the outermost Spring element."
          );
        }

        /* istanbul ignore next */
        if (nested > 1) {
          // eslint-disable-next-line no-console
          console.warn(
            `Spring: this component shouldn't be nested more than once, found at level: ${nested} of nesting instead.`
          );
        }

        return (
          <MarkupComponent
            className={classnames("spring", className, {
              "spring--nested": nested,
              "spring--nested--small": nested && isWide,
              "spring--sm": !nested && size === "small",
              "spring--md": !nested && size === "medium"
            })}
          >
            <SpringNestingContext.Provider value={nested + 1}>
              {children}
            </SpringNestingContext.Provider>
          </MarkupComponent>
        );
      }}
    </SpringNestingContext.Consumer>
  );
};

export default Spring;
