/* @flow */

import * as React from "react";
import Select from "../Select";
import FormField, { type FormFieldProps } from "./_FormField";

type Props = {
  options: Array<{ label: string | number, value: string | number }>,
  styles?: { [string]: () => Object }
} & FormFieldProps;

const FormSelectWithSearch = ({
  options,
  styles = {},
  label,
  name,
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  ...props
}: Props) => {
  return (
    <FormField
      {...{
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden
      }}
    >
      <Select options={options} styles={styles} {...props} />
    </FormField>
  );
};

export default FormSelectWithSearch;
