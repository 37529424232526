// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Badge-module__badge--success--3ldKV .Badge-module__box--1uxEv{background-color:#428600}.Badge-module__badge--warning--2RKfP .Badge-module__box--1uxEv{background-color:#eb9700}.Badge-module__badge--danger--2Eslq .Badge-module__box--1uxEv{background-color:#e60000}.Badge-module__badge--undecided--2NWoe .Badge-module__box--1uxEv{background-color:#5e2750}.Badge-module__badge--stateless--7HExQ .Badge-module__box--1uxEv{background-color:#666}.Badge-module__badge--stateless--7HExQ.Badge-module__badge--dot--caMD6 .Badge-module__box--1uxEv{background-color:#333}.Badge-module__badge--dot--caMD6 .Badge-module__box--1uxEv{display:inline-block;border-radius:20px;position:relative;z-index:1;padding:5px}.Badge-module__badge--full-width--jfQtB .Badge-module__box--1uxEv{width:100%}.Badge-module__badge--padding--3DIPs .Badge-module__box--1uxEv{padding:5px}.Badge-module__badge--centered--1XIEX .Badge-module__box--1uxEv{text-align:center}.Badge-module__label--ea2yQ{margin-top:0}.Badge-module__box--1uxEv{color:#fff;display:inline-block;padding:0 1em}\n", ""]);
// Exports
exports.locals = {
	"badge--success": "Badge-module__badge--success--3ldKV",
	"box": "Badge-module__box--1uxEv",
	"badge--warning": "Badge-module__badge--warning--2RKfP",
	"badge--danger": "Badge-module__badge--danger--2Eslq",
	"badge--undecided": "Badge-module__badge--undecided--2NWoe",
	"badge--stateless": "Badge-module__badge--stateless--7HExQ",
	"badge--dot": "Badge-module__badge--dot--caMD6",
	"badge--full-width": "Badge-module__badge--full-width--jfQtB",
	"badge--padding": "Badge-module__badge--padding--3DIPs",
	"badge--centered": "Badge-module__badge--centered--1XIEX",
	"label": "Badge-module__label--ea2yQ"
};
module.exports = exports;
