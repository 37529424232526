/* @flow */

import * as React from "react";

import Icon from "../Icon";
import classnames from "classnames";

type Props = {
  children: React.ChildrenArray<React.Element<typeof QuickLinks.Item>>,
  isRadio?: boolean
};

const QuickLinks = ({ children, isRadio }: Props) => {
  const [selectedLink: string, changeSelectedLink] = React.useState("");
  const [content: React.Node, changeContent] = React.useState("");

  const updateContent = (tabLabel, content) => {
    if (tabLabel === selectedLink && !isRadio) {
      changeSelectedLink("");
      changeContent("");
    } else {
      changeSelectedLink(tabLabel);
      changeContent(content);
    }
  };

  return (
    <nav className="quicklinks">
      <h2 className="visually-hidden">Quicklinks</h2>
      <div className="quicklinks__hidden-scrollbar">
        <div className="spring">
          <nav className="quicklinks__navigation">
            {React.Children.map(children, child =>
              React.cloneElement(child, { selectedLink, updateContent })
            )}
          </nav>
        </div>
      </div>
      {content && (
        <div className="quicklinks__content quicklinks__content--active no-gutter--bottom">
          {content}
        </div>
      )}
    </nav>
  );
};

type ItemProps = {
  icon: SVGImport,
  children?: React.Node,
  label: string,
  defaultSelected?: boolean,
  onClick?: () => void,
  selectedLink?: string,
  updateContent?: (string, React.Node) => void
};

QuickLinks.Item = ({
  icon,
  children,
  label,
  defaultSelected,
  onClick,
  selectedLink,
  updateContent,
  ...props
}: ItemProps) => {
  React.useEffect(() => {
    if (defaultSelected && children && updateContent)
      updateContent(label, children);
  }, []);

  const onClickHandler = () => {
    if (children && updateContent) updateContent(label, children);
    if (onClick) onClick();
  };

  return (
    <a
      className={classnames("quicklinks__item", {
        "quicklinks__item--active": selectedLink === label
      })}
      onClick={onClickHandler}
      {...props}
    >
      {!!icon && (
        <Icon source={icon} size="large" className="quicklinks__icon" />
      )}
      <span className="quicklinks__heading">{label}</span>
    </a>
  );
};

export default QuickLinks;
