/* @flow */

import * as React from "react";

type DialValueProps = {
  label?: React.Node,
  value?: React.Node,
  subLabel?: React.Node
};

const DialValue = ({ label, value, subLabel }: DialValueProps) => (
  <div className="dial__value-wrapper">
    {label && <div className="dial__value-label">{label}</div>}
    {value && <div className="dial__value">{value}</div>}
    {subLabel && <span>{subLabel}</span>}
  </div>
);

export default DialValue;
