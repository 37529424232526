/* @flow */

import React, { useEffect, useRef } from "react";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Grid from "../Grid";
import Icon from "../Icon";
import type { FilterSortProps } from "./types";

const FilterSort = ({
  children,
  label = "Sort by",
  onChange
}: FilterSortProps) => {
  const [currentSort, setCurrentSort] = React.useState(children[0].props.value);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false;
    else onChange(currentSort);
  }, [currentSort]);

  const changeCurrentSort = ({ target: { value } }) => {
    setCurrentSort(value);
  };

  return (
    <form className="actions__sort-by" method="get" action="#">
      <label className="grid">
        <Grid>
          <Grid.Item width="1/5" verticalAlign="middle">
            <span className="actions__sort-label">
              {label ? label : "Sort by"}
            </span>
          </Grid.Item>
          <Grid.Item width="4/5" verticalAlign="middle">
            <span className="form__input form__input--light form__input--selectable actions__sort">
              <select
                className="form__select"
                name="sort"
                value={currentSort}
                onChange={e => changeCurrentSort(e)}
              >
                {children}
              </select>
              <Icon
                source={Svg.ChevronDown}
                size="small"
                className="form__icon"
              />
            </span>
          </Grid.Item>
        </Grid>
      </label>
    </form>
  );
};

export default FilterSort;
