/* @flow */

import classnames from "classnames";
import * as React from "react";
import Background, { type ResponsiveImageSet } from "../Background";
import Heading from "../Heading";

type Props = {
  children?: React.Node,
  className?: string,
  backgroundClassName?: string,
  hasNoGradient?: boolean,
  isAnimated?: boolean
} & ResponsiveImageSet;

type MessageProps = {
  title?: React.Node,
  subTitle?: React.Node,
  children?: React.Node
};

const HeroMessage = ({ title, subTitle, children }: MessageProps) => {
  return (
    <div className="hero__message">
      {title ? (
        <Heading className="hero__heading" level={1} hasNoGutter>
          {title}
        </Heading>
      ) : null}
      {subTitle ? (
        <Heading
          className="hero__sub-message"
          level={4}
          component="h2"
          isTrailing
        >
          {subTitle}
        </Heading>
      ) : null}
      {children}
    </div>
  );
};

export default class Hero extends React.Component<Props> {
  static Message = HeroMessage;

  render() {
    const {
      children,
      className,
      backgroundClassName,
      hasNoGradient,
      isAnimated,
      images,
      imageBase
    } = this.props;

    return (
      <div
        className={classnames("hero", className, {
          "hero--animate": isAnimated
        })}
      >
        <Background
          isCover
          className={classnames("hero__background", backgroundClassName, {
            "hero__background--no-tint": hasNoGradient
          })}
          images={images}
          imageBase={imageBase}
        />
        <div className="hero__band">{children}</div>
      </div>
    );
  }
}
