/* @flow */

import classnames from "classnames";
import * as React from "react";
import Clickable, { type ClickableAction } from "../Clickable";
import SideChevron from "../SideChevron";
import localStyles from "./Accordion.module.scss";

type Props = {
  children: React.Node,
  component?: React.ElementType,
  className?: string,
  headingClassName?: string,
  onClick?: () => void,
  action?: ClickableAction,
  chevronClassName?: string,
  chevronColor?: "white" | "red"
};
type State = void;

export default class AccordionLinkItem extends React.Component<Props, State> {
  render() {
    const {
      component: Component = "div",
      className,
      headingClassName,
      onClick,
      action,
      chevronClassName,
      chevronColor,
      children
    } = this.props;

    return (
      <Component className={classnames("accordion__item", className)}>
        <Clickable
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          {...action}
          className={classnames(
            "accordion__heading",
            localStyles["accordion__link-item"],
            headingClassName
          )}
        >
          <SideChevron
            chevronDirection="right"
            chevronContainerClassName="accordion__chevron"
            chevronClassName={chevronClassName}
            chevronColor={chevronColor}
          >
            {children}
          </SideChevron>
        </Clickable>
      </Component>
    );
  }
}
