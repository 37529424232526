/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classNames from "classnames";
import without from "lodash.without";
import React, { useEffect, useRef, useState } from "react";
import uuidv4 from "uuid/v4";
import Form from "../Form";
import ToggleSwitch from "../ToggleSwitch";
import OutsideClick from "../OutsideClick";
import styles from "./SelectDropdown.module.scss";

type Props = {
  placeholder?: string,
  name: string,
  options: $ReadOnlyArray<{
    title: string,
    subTitle: string,
    id: string
  }>,
  value?: $ReadOnlyArray<string>,
  isMultiSelect?: boolean,
  isAlwaysOpen?: boolean,
  isOpen?: boolean,
  onChange: (value: $ReadOnlyArray<string>) => void
};

const SelectDropdown = ({
  name,
  placeholder,
  options,
  value = [],
  isMultiSelect = false,
  isAlwaysOpen = false,
  isOpen = isAlwaysOpen,
  onChange,
  ...props
}: Props) => {
  const [query, setQuery] = useState("");
  const [matches, setMatches] = useState(options);
  const [isFilterOpen, setIsFilterOpen] = useState(isOpen);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (query.length) {
        const matches = options.filter(option =>
          `${option.title} ${option.subTitle}`
            .toLowerCase()
            .includes(query.toLowerCase())
        );
        setMatches(matches);
      } else {
        setMatches(options);
      }
    }, 500);
    return () => {
      clearTimeout(debounce);
    };
  }, [query]);

  const handleOnChange = (option: string, checked: ?boolean) => {
    if (!value.includes(option)) {
      onChange([...value, option]);
    }
    if (value.includes(option)) {
      onChange(without(value, option));
    }
  };

  return (
    <OutsideClick
      handler={() => setIsFilterOpen(false)}
      className={classNames(
        "select-dropdown",
        styles["select-dropdown__container"]
      )}
    >
      {/* $FlowFixMe: false negative */}
      <Form.Input
        {...{ name, placeholder }}
        value={query}
        iconSource={Svg.Search}
        isIcon
        onFocus={() => setIsFilterOpen(true)}
        onChange={(event: SyntheticEvent<HTMLInputElement>) =>
          setQuery(event.currentTarget.value)
        }
        {...props}
      />
      {isFilterOpen && (
        <div className={styles["select-dropdown__dropdown"]}>
          <ul className={styles["select-dropdown__list"]}>
            {matches.map(({ id: option, title, subTitle }, index) => (
              <li key={index} className={styles["select-dropdown__list-item"]}>
                <label>
                  <div
                    className={classNames(styles["select-dropdown__option"], {
                      [styles["select-dropdown__option--radio"]]: !isMultiSelect
                    })}
                  >
                    <div className={styles["select-dropdown__label"]}>
                      <div className={styles["select-dropdown__title"]}>
                        {title}
                      </div>
                      <div className={styles["select-dropdown__subtitle"]}>
                        {subTitle}
                      </div>
                    </div>
                    {isMultiSelect && (
                      <ToggleSwitch
                        value={value.includes(option)}
                        hideLabel
                        onChange={checked => {
                          handleOnChange(option, checked);
                        }}
                      />
                    )}
                    {!isMultiSelect && (
                      <input
                        {...{ name }}
                        checked={value.includes(option)}
                        className={styles["select-dropdown__radio"]}
                        type="radio"
                        value={option}
                        onChange={event => {
                          onChange([event.currentTarget.value]);
                        }}
                      />
                    )}
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </OutsideClick>
  );
};

export default SelectDropdown;
