/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import React from "react";
import Icon from "../Icon";

const Brand = ({ ...props }: any) => (
  <div className="brand" {...props}>
    <Icon source={Svg.VodafoneLogo} className="brand__logo" isFilled />
  </div>
);

export default Brand;
