/** @flow */

import * as React from "react";

import Icon from "../Icon";
import LanguageDropDown from "./_LanguageDropDown";
import Link from "../Link";
import NavItem from "./_NavItem";
import SideChevron from "../SideChevron";
import SlideToggle from "../SlideToggle";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";

type Props = {
  /** Use this property to pass children to a different part of the structure */
  languageSection?: React.Node,
  children?: React.Node,
  /** A function that is called when the side bar is opened or closed, it's passed a boolean */
  onToggle?: boolean => void,
  /** Called when the side bar is opened */
  onOpen?: () => void,
  /** Called when the side bar is closed */
  onClose?: () => void
};

const SideBar = ({
  languageSection,
  children,
  onToggle,
  onOpen,
  onClose,
  ...props
}: Props) => {
  const [isOpen, toggleDropdown] = React.useState(false);

  const firstRender = React.useRef(true);
  React.useEffect(() => {
    if (!firstRender.current) {
      if (onToggle) onToggle(isOpen);
      if (onOpen && isOpen) onOpen();
      if (onClose && !isOpen) onClose();
    } else firstRender.current = false;
  }, [isOpen]);

  return (
    <NavItem
      className={classnames(
        "navigation__item--right navigation__hide navigation__item--clickable hide--lg",
        {
          "navigation__item--active": isOpen
        }
      )}
    >
      <Link
        {...props}
        type="htmlLink"
        onClick={() => toggleDropdown(!isOpen)}
        className={classnames(
          "navigation__link navigation__link--icon navigation__link--icon-last navigation__link--menu",
          {
            "navigation__link--active": isOpen
          }
        )}
      >
        <span className="visually-hidden">
          <span className="navigation__accessibility">Close</span>Menu
        </span>
        {!isOpen && (
          <Icon
            source={Svg.Menu}
            size="small"
            className="navigation__icon navigation__toggle"
          />
        )}
        {isOpen && (
          <Icon
            source={Svg.Close}
            size="small"
            className="navigation__icon navigation__close"
          />
        )}
      </Link>

      <div
        className={classnames("navigation navigation--tertiary", {
          "navigation--display": isOpen
        })}
      >
        {languageSection}
        <ul className="accordion navigation__accordion">{children}</ul>
      </div>
    </NavItem>
  );
};
const NavigationAccordionNestContext = React.createContext<number>(0);

type NavigationAccordionProps = {
  children?: React.Node,
  label: string,
  isGoTo?: boolean,
  isButton?: boolean
};

SideBar.NavAccordion = ({
  children,
  label,
  isGoTo,
  isButton,
  ...props
}: NavigationAccordionProps) => {
  const [isOpen, toggleDropdown] = React.useState(false);

  const onClickHandler = () => {
    toggleDropdown(!isOpen);
  };

  return (
    <NavigationAccordionNestContext.Consumer>
      {nested => {
        return (
          <NavItem
            className={classnames({
              "navigation__item--nested": nested > 0,
              "navigation__item--tertiary hide--lg": nested === 0,
              "navigation__item--site": isButton,
              "navigation__item--go-to": isGoTo
            })}
          >
            <Link
              {...props}
              className={classnames(
                "navigation__link chevron chevron--inline",
                {
                  "accordion__heading--active": isOpen,
                  navigation__site: isButton
                }
              )}
              onClick={!isGoTo ? onClickHandler : undefined}
              onKeyDown={({ key }) => {
                if (key === "Enter" && !isGoTo) onClickHandler();
              }}
              tabIndex="0"
              type="htmlLink"
            >
              <SideChevron
                chevronDirection={isGoTo || isButton ? "right" : "down"}
                chevronContainerClassName="chevron__container--float"
                chevronClassName="accordion__chevron navigation__chevron"
              >
                {label}
              </SideChevron>
            </Link>

            {children && (
              <SlideToggle isOpen={isOpen}>
                <ul className="navigation__list">
                  <NavigationAccordionNestContext.Provider value={nested + 1}>
                    {children}
                  </NavigationAccordionNestContext.Provider>
                </ul>
              </SlideToggle>
            )}
          </NavItem>
        );
      }}
    </NavigationAccordionNestContext.Consumer>
  );
};

export default SideBar;
