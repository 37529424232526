/* @flow */

import * as React from "react";

import AdvancedTableContext from "./_AdvancedTableContext";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Table from "../Table";
import FlexTable from "../FlexTable";
import classnames from "classnames";
import styles from "./AdvancedTable.module.scss";

type Props = {
  backendSortingProps?: {
    onSort: string => void,
    direction: string,
    sortBy: string
  }
};

const AdvancedTableSortingHeader = ({ backendSortingProps }: Props) => {
  const { layout, headerGroups, resizeEnabled } = React.useContext(
    AdvancedTableContext
  );
  const MarkupComponent = layout === "flex" ? FlexTable : Table;

  return (
    <MarkupComponent.Header>
      {headerGroups.map((headerGroup, index) => (
        <MarkupComponent.Row
          isHeader
          key={index}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column, index) => (
            <React.Fragment key={index}>
              <MarkupComponent.Heading
                {...(backendSortingProps
                  ? column.getHeaderProps()
                  : column.getHeaderProps(column.getSortByToggleProps()))}
                onClick={
                  backendSortingProps
                    ? () => backendSortingProps.onSort(column.id)
                    : e =>
                        column
                          .getHeaderProps(column.getSortByToggleProps())
                          .onClick(e)
                }
              >
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  {column.render("Header")}
                  {column.canSort && (
                    <div
                      style={{
                        width: "15px",
                        fontSize: "12px",
                        userSelect: "none",
                        marginLeft: "10px"
                      }}
                    >
                      <div
                        className={classnames({
                          [styles[
                            "advanced-table--selected-column"
                          ]]: backendSortingProps
                            ? backendSortingProps.sortBy === column.id &&
                              backendSortingProps.direction === "ASC"
                            : !column.isSortedDesc && column.isSorted
                        })}
                      >
                        ▲
                      </div>
                      <div
                        className={classnames({
                          [styles[
                            "advanced-table--selected-column"
                          ]]: backendSortingProps
                            ? backendSortingProps.sortBy === column.id &&
                              backendSortingProps.direction === "DSC"
                            : column.isSortedDesc && column.isSorted
                        })}
                      >
                        ▼
                      </div>
                    </div>
                  )}
                </div>
              </MarkupComponent.Heading>
              {column.canResize && resizeEnabled && (
                <div
                  {...column.getResizerProps()}
                  className={classnames(styles["advanced-table__resizer"], {
                    [styles["advanced-table__resizer--is-resizing"]]:
                      column.isResizing
                  })}
                />
              )}
            </React.Fragment>
          ))}
        </MarkupComponent.Row>
      ))}
    </MarkupComponent.Header>
  );
};

export default AdvancedTableSortingHeader;
