// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SortableDropDown-module__choose__column--container--3hjo7{width:inherit;background-color:#fff;border:1px solid #999;border-top:none;display:grid;padding:10px;position:absolute;z-index:999}.SortableDropDown-module__choose__column--container--light--1gFKy{border:1px solid #ebebeb}.SortableDropDown-module__choose__column--container--3hjo7 button{margin:auto}.SortableDropDown-module__choose__column--option--2tUVr{padding:5px 10px;display:flex;justify-content:space-between;align-items:center;cursor:move}.SortableDropDown-module__choose__column--option--2tUVr div:first-child{padding:10px 0;display:inline}.SortableDropDown-module__choose__column--option--2tUVr div:first-child div{margin-right:5px}\n", ""]);
// Exports
exports.locals = {
	"choose__column--container": "SortableDropDown-module__choose__column--container--3hjo7",
	"choose__column--container--light": "SortableDropDown-module__choose__column--container--light--1gFKy",
	"choose__column--option": "SortableDropDown-module__choose__column--option--2tUVr"
};
module.exports = exports;
