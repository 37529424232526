/* @flow */

import * as React from "react";

type Props = {
  row: any
};

const AdvancedTableCheckBoxCell = ({ row }: Props) => (
  <div style={{ textAlign: "center", margin: "auto", width: "20px" }}>
    <input type="checkbox" {...row.getToggleRowSelectedProps()} />
  </div>
);

export default AdvancedTableCheckBoxCell;
