import { Svg } from "@vf-dcl/vodafone-ws2";
import { ButtonBack, ButtonNext } from "pure-react-carousel";
import * as React from "react";
import Icon from "../Icon";

const CarouselControls = () => (
  <div className="carousel__controls carousel__controls--visible">
    <ButtonBack className="carousel__control carousel__control--left">
      <span className="visually-hidden">Previous page</span>
      <Icon
        source={Svg.ChevronLeft}
        size="small"
        className="carousel__control-icon"
      />
    </ButtonBack>
    <ButtonNext className="carousel__control carousel__control--right">
      <span className="visually-hidden">Next page</span>
      <Icon
        source={Svg.ChevronRight}
        size="small"
        className="carousel__control-icon"
      />
    </ButtonNext>
  </div>
);

export default CarouselControls;
