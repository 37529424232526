import * as React from "react";

type Props = {
  cell: any,
  row: any,
  column: any,
  onCellEdit?: any => void
};

const AdvancedTableEditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  onCellEdit
}: Props) => {
  const input = React.useRef(null);
  const [value, setValue] = React.useState(initialValue);
  const [isInEditMode, setIsInEditMode] = React.useState(false);

  React.useEffect(() => {
    if (isInEditMode && input.current) {
      input.current.focus();
    }
  }, [isInEditMode]);

  const onClick = e => {
    if (!isInEditMode) {
      setIsInEditMode(true);
    }
  };

  const onChange = e => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    onCellEdit(index, id, value);
    setIsInEditMode(false);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="advanced-table__editable-cell" onClick={onClick}>
      {isInEditMode ? (
        <input
          // $FlowFixMe: false negative
          ref={input}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : (
        <label>{value}</label>
      )}
    </div>
  );
};

export default AdvancedTableEditableCell;
