// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Box-module__box--3SYNP{background:#6f6e6b}\n", ""]);
// Exports
exports.locals = {
	"box": "Box-module__box--3SYNP"
};
module.exports = exports;
