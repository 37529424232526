/* @flow */

import classnames from "classnames";
import * as React from "react";
import styles from "./Table.module.scss";

type Props = {
  children: React.Node,
  className?: string
};

type tableProps = {
  isContextual?: boolean,
  isAlt?: boolean
} & Props;

const Table = ({
  children,
  isContextual,
  isAlt,
  className,
  ...props
}: tableProps) => {
  return (
    <table
      {...props}
      className={classnames("table", styles.table, className, {
        "table--contextual": isContextual,
        "table--alt": isAlt
      })}
    >
      {children}
    </table>
  );
};

type tableRowProps = {
  isHeader?: boolean
} & Props;

Table.Heading = ({ children, className, ...props }: Props) => {
  return (
    <th {...props} className={classnames("table__th", className)}>
      {children}
    </th>
  );
};

Table.Row = ({ children, isHeader, className, ...props }: tableRowProps) => {
  return (
    <tr
      {...props}
      className={classnames("table__tr", className, {
        "table__tr--header": isHeader
      })}
    >
      {children}
    </tr>
  );
};

Table.Data = ({ children, className, ...props }: Props) => {
  return (
    <td {...props} className={classnames("table__td", className)}>
      {children}
    </td>
  );
};

Table.Header = ({ children, className, ...props }: Props) => {
  return (
    <thead {...props} className={className}>
      {children}
    </thead>
  );
};

Table.Body = ({ children, className, ...props }: Props) => {
  return (
    <tbody {...props} className={classnames("table__body", className)}>
      {children}
    </tbody>
  );
};

Table.Footer = ({ children, className, ...props }: Props) => {
  return (
    <tfoot {...props} className={className}>
      {children}
    </tfoot>
  );
};
export default Table;
