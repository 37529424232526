/* @flow */
import { CarouselProvider } from "pure-react-carousel";
import * as React from "react";
import CarouselContent from "./_CarouselContent.web";

type Props = {
  /** An array of <Carousel.Item/> */
  children: React.ChildrenArray<React.Element<typeof Carousel.Item>>,
  /** The index of the initially displayed slide */
  initialSlide?: number,
  /** Number of visible slides per page. This should be lower than the total number of slides.*/
  visibleSlides?: number,
  /** By default the active slide sits in the first (left) position, but you can set the carousel to have the active slide in the middle. */
  center?: boolean,
  /** Define if slide display should be infinite. */
  infinite?: boolean,
  /** Define if the controls should be visible. */
  showControls?: boolean,
  /** Define if slide pagination should be visible. */
  showPagination?: boolean,
  /** The carousel can be navigated through drag events using the mouse. */
  dragDisabled?: boolean,
  /** When touch events are available in the browser, the carousel can be navigated through swipe events. */
  touchDisabled?: boolean,
  /** Define the number of visible slides per viewport. */
  breakpoints?: {
    desktop: number,
    tablet: number,
    mobile: number
  }
};

const Carousel = ({
  children,
  visibleSlides = 1,
  center = false,
  initialSlide = 0,
  infinite = false,
  showControls = React.Children.count(children) > visibleSlides,
  showPagination = true,
  dragDisabled = false,
  touchDisabled = false,
  breakpoints = {
    desktop: visibleSlides,
    tablet: visibleSlides < 2 ? visibleSlides : 2,
    mobile: 1
  },
  ...props
}: Props) => {
  let throttle;
  const [slidesPerPage, setSlidesPerPage] = React.useState(visibleSlides);
  const totalSlides = React.Children.count(children);
  let pageCount = totalSlides - slidesPerPage + 1;
  if (pageCount < 1) {
    pageCount = 1;
  }

  const viewportBreakpoints = {
    desktop: 1024,
    tablet: 640
  };

  React.useEffect(() => {
    computeSlidesPerPage();
    window.addEventListener("resize", handleWindowResize, false);
    return () => {
      window.clearTimeout(throttle);
      window.removeEventListener("resize", handleWindowResize, false);
    };
  }, []);

  const handleWindowResize = () => {
    window.clearTimeout(throttle);
    throttle = window.setTimeout(computeSlidesPerPage, 400);
  };

  const computeSlidesPerPage = () => {
    const width = window.innerWidth;
    if (width > viewportBreakpoints.desktop) {
      setSlidesPerPage(breakpoints.desktop);
    } else if (width > viewportBreakpoints.tablet) {
      setSlidesPerPage(breakpoints.tablet);
    } else {
      setSlidesPerPage(breakpoints.mobile);
    }
  };

  return (
    <CarouselProvider
      totalSlides={totalSlides}
      visibleSlides={slidesPerPage}
      infinite={infinite}
      currentSlide={center ? Math.floor(pageCount / 2) : initialSlide}
      touchEnabled={!touchDisabled}
      dragEnabled={!dragDisabled}
      {...props}
    >
      <CarouselContent
        showControls={showControls}
        showPagination={showPagination}
      >
        {children}
      </CarouselContent>
    </CarouselProvider>
  );
};

type ItemProps = {
  children: React.Node
};

const CarouselItem = ({ children }: ItemProps) => <>{children}</>;

Carousel.Item = CarouselItem;
export default Carousel;
