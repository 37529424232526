/* @flow */

import * as React from "react";

import { type ClickableAction } from "../Clickable";
import Link from "../Link";
import NavItem from "./_NavItem";
import classnames from "classnames";

const NavDropDownNestContext = React.createContext<number>(0);

type Props = {
  children?: React.ChildrenArray<React.Element<typeof NavDropDown>>,
  label: string,
  action?: ClickableAction
};

const NavDropDown = ({ children, label, ...props }: Props) => {
  const [isActive, toggleActive] = React.useState(false);
  return (
    <NavDropDownNestContext.Consumer>
      {nested => (
        <NavItem
          className={classnames({
            "hide--sm hide--md": nested === 0,
            "navigation__item--parent": nested > 0,
            "navigation__item--secondary-sub": nested === 2,
            "navigation__item--active": isActive
          })}
          onMouseEnter={() => toggleActive(true)}
          onMouseLeave={() => toggleActive(false)}
        >
          <Link
            {...props}
            type="htmlLink"
            className={classnames("navigation__link", {
              "navigation__link--main": nested === 0,
              "navigation__link--main-secondary": nested === 1
            })}
          >
            {label}
            <span className="visually-hidden">menu</span>
          </Link>
          <div
            className={classnames("navigation", {
              "navigation--secondary": nested === 0,
              "navigation--secondary-sub": nested === 1,
              "navigation--display": isActive
            })}
          >
            {nested === 0 && (
              <div className="navigation__spring navigation__spring--primary">
                <ul className="navigation__content navigation__list  navigation__content--secondary">
                  <NavDropDownNestContext.Provider value={nested + 1}>
                    {children}
                  </NavDropDownNestContext.Provider>
                </ul>
              </div>
            )}
            {nested === 1 && (
              <ul className="navigation__content">
                <NavDropDownNestContext.Provider value={nested + 1}>
                  {children}
                </NavDropDownNestContext.Provider>
              </ul>
            )}
          </div>
        </NavItem>
      )}
    </NavDropDownNestContext.Consumer>
  );
};

export default NavDropDown;
