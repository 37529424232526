/* @flow */

import * as React from "react";

import { useDrag, useDrop } from "react-dnd";

import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";
import ToggleSwitch from "../ToggleSwitch";
import styles from "./SortableDropDown.module.scss";

type Props = {
  label: string,
  isDisabled?: boolean,
  index?: number,
  moveCard?: (number, number) => void,
  onChange?: string => void,
  selected?: Array<string>,
  isChecked?: boolean
};

const _DropDownOption = ({
  label,
  isDisabled,
  index = 0,
  moveCard,
  onChange,
  selected = []
}: Props) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (moveCard) moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", label, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      className={styles["choose__column--option"]}
      ref={ref}
      style={{ opacity }}
    >
      <div>
        <ToggleSwitch
          hideLabel
          name={label}
          isDisabled={isDisabled}
          value={selected.includes(label)}
          onChange={() => {
            if (onChange) onChange(label);
          }}
        />
        {label}
      </div>
      <Icon source={Svg.Menu} className="form__icon" />
    </div>
  );
};
export default _DropDownOption;
