/* @flow */

import * as React from "react";
import classnames from "classnames";
import pluralize from "pluralize";
import { Svg } from "@vf-dcl/vodafone-ws2";
import FilterContext from "./_FilterContext";
import Icon from "../Icon";
import localStyles from "./Filter.module.scss";

const FilterIcon = () => (
  <FilterContext.Consumer>
    {({ isOpen, toggleSidebar, activeFilters, clearFilters }) => {
      let allFilters = [];
      Object.keys(activeFilters).forEach(filter => {
        allFilters = allFilters.concat(activeFilters[filter]);
      });

      return (
        <div className={localStyles["filters--icon"]}>
          <button
            className={classnames({
              "filters__sidebar-toggle--active": isOpen
            })}
            onClick={toggleSidebar}
          >
            <Icon
              className={classnames(localStyles["filters--icon__icon"], {
                [localStyles["filters--icon__icon--active"]]: isOpen
              })}
              source={Svg.Filter}
              size="small"
            />
            <span
              className={classnames(
                "filters__counter",
                localStyles["filters--icon__counter"]
              )}
            >
              {allFilters.length}
            </span>
          </button>
          <button
            className={classnames(
              "filters__clear",
              localStyles["filters--icon__clear"]
            )}
            onClick={clearFilters}
          >
            {pluralize("Clear filter", allFilters.length)}
          </button>
        </div>
      );
    }}
  </FilterContext.Consumer>
);

export default FilterIcon;
