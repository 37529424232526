/* @flow */

import * as React from "react";

import Button from "../Button";
import classnames from "classnames";
import styles from "./SortableDropDown.module.scss";

type ContainerProps = {
  children: React.Node,
  onChange: string => void,
  selectedOptions: Array<string>,
  setDefaultHandler: () => void,
  isLight?: boolean,
  moveCard: (number, number) => void
};

const _DropDownContainer = ({
  children,
  onChange,
  selectedOptions,
  setDefaultHandler,
  isLight,
  moveCard
}: ContainerProps) => (
  <div
    className={classnames(styles["choose__column--container"], {
      [styles["choose__column--container--light"]]: isLight
    })}
  >
    <Button rank="tertiary" onClick={setDefaultHandler}>
      Default View
    </Button>
    {React.Children.map(children, (child, index) =>
      React.cloneElement(child, {
        index,
        moveCard,
        onChange: onChange,
        selected: selectedOptions
      })
    )}
  </div>
);

export default _DropDownContainer;
