// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Upload-module__browse-button--21Nep{margin-top:10px}.Upload-module__upload-container--2GABf{border:2px dashed #ccc}.Upload-module__upload-text--1u2IX{padding:40px 0}.Upload-module__upload-input--1UP-5{display:none}.Upload-module__file-list-container--2jtvG{display:flex;flex-flow:row wrap;justify-content:left}.Upload-module__file-container--nNDHJ{max-width:150px;padding:10px}.Upload-module__file-label--2W6gA{max-width:100px;overflow-wrap:break-word;margin-bottom:10px}\n", ""]);
// Exports
exports.locals = {
	"browse-button": "Upload-module__browse-button--21Nep",
	"upload-container": "Upload-module__upload-container--2GABf",
	"upload-text": "Upload-module__upload-text--1u2IX",
	"upload-input": "Upload-module__upload-input--1UP-5",
	"file-list-container": "Upload-module__file-list-container--2jtvG",
	"file-container": "Upload-module__file-container--nNDHJ",
	"file-label": "Upload-module__file-label--2W6gA"
};
module.exports = exports;
