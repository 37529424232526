/* @flow */

import * as React from "react";

type Props = {
  percent: number,
  color?: "primary" | "secondary" | "seance" | "cerulean"
};

const ProgressBar = ({ percent, color = "primary" }: Props) => {
  return (
    <div className={`progress progress--${color}`}>
      <div className="progress__bar" style={{ width: `${percent}%` }} />
    </div>
  );
};

export default ProgressBar;
