/* @flow */

import classnames from "classnames";
import * as React from "react";
import GridContext from "./_GridContext";
import GridItem from "./_GridItem";

type Props = {
  children: React.Node,
  component?: React.ElementType,
  className?: string,
  hasAutoHeight?: boolean,
  hasHorizontalGutter?: boolean,
  hasVerticalGutter?: boolean
};

type State = {
  hasHorizontalGutter: boolean,
  hasVerticalGutter: boolean
};

export default class Grid extends React.Component<Props, State> {
  static Item = GridItem;

  state = {
    hasHorizontalGutter: this.props.hasHorizontalGutter || false,
    hasVerticalGutter: this.props.hasVerticalGutter || false
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      Boolean(nextProps.hasHorizontalGutter) !==
        prevState.hasHorizontalGutter ||
      Boolean(nextProps.hasVerticalGutter) !== prevState.hasVerticalGutter
    ) {
      return {
        hasHorizontalGutter: Boolean(nextProps.hasHorizontalGutter),
        hasVerticalGutter: Boolean(nextProps.hasVerticalGutter)
      };
    }

    return null;
  }

  render() {
    const {
      children,
      component: Component = "div",
      hasAutoHeight,
      className = "",
      hasHorizontalGutter,
      hasVerticalGutter
    } = this.props;

    return (
      <GridContext.Provider value={this.state}>
        <Component
          className={classnames(
            "grid",
            {
              "grid--auto": hasAutoHeight,
              "grid--gutter": hasHorizontalGutter,
              "grid--gutter-vertical": hasVerticalGutter
            },
            className
          )}
        >
          {children}
        </Component>
      </GridContext.Provider>
    );
  }
}
