/* @flow */
import * as React from "react";
import Icon from "../Icon";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";

type Props = {
  /** The rating value. */
  rating?: number,
  /** Specifies CSS classes to be added to the root .rating container. */
  className?: string
};

const Rating = ({ rating = 0, className }: Props) => (
  <div className={classnames("rating", className)}>
    <Icon
      className={"rating__icon rating__icon--no-rating"}
      source={Svg.Rating0}
    />
    <Icon
      className={classnames(
        "rating__icon",
        "rating__icon--rating",
        `rating__icon--rating--${rating}`
      )}
      source={Svg.Rating5}
    />
  </div>
);

export default Rating;
