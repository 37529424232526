/* @flow */

import * as React from "react";

import classnames from "classnames";

type Props = {
  children: React.Node,
  className?: string
};

const NavItem = ({ children, className, ...props }: Props) => (
  <li className={classnames("navigation__item", className)} {...props}>
    {children}
  </li>
);

export default NavItem;
