/* @flow */

import * as React from "react";
import Dial from "../Dial";
import { Svg } from "@vf-dcl/vodafone-ws2";
import localStyles from "./Upload.module.scss";

type Props = {
  file: File,
  onUpload: (file: File, updateProgress: (progress: number) => void) => void,
  onRemoval: (file: File) => void
};

const FileComp = ({ file, onUpload, onRemoval }: Props) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    onUpload(file, setProgress);
  }, []);

  const handleOnClick = () => onRemoval(file);

  const name =
    file.name.length > 30 ? `${file.name.substr(0, 30)}…` : file.name;

  return (
    <Dial
      value={progress}
      maxValue={100}
      color="finn"
      isBody
      hasInitialAnimation={false}
    >
      <Dial.Value
        subLabel={
          <div className={localStyles["file-label"]} title={file.name}>
            {name}
          </div>
        }
      />
      <Dial.SubCTA
        action={{ type: "default", onClick: handleOnClick }}
        icon={Svg.Close}
        alt="Delete file"
      />
    </Dial>
  );
};

export default FileComp;
