// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SideChevron-module__chevron--inline--Y2L9K .SideChevron-module__chevron__container--2tBd8{margin-bottom:3px}.SideChevron-module__chevron__text--push--2Jfx_{padding-left:15px;padding-right:0}\n", ""]);
// Exports
exports.locals = {
	"chevron--inline": "SideChevron-module__chevron--inline--Y2L9K",
	"chevron__container": "SideChevron-module__chevron__container--2tBd8",
	"chevron__text--push": "SideChevron-module__chevron__text--push--2Jfx_"
};
module.exports = exports;
