/* @flow */

import * as React from "react";

import Link from "../Link";
import NavItem from "./_NavItem";
import classnames from "classnames";

type Props = {
  children: React.Node
};

const TopBar = ({ children, ...props }: Props) => (
  <div className="navigation" {...props}>
    <div className="spring">
      <ul className="navigation__list navigation__global navigation__hide no-gutter--all">
        <li className="navigation__spring">{children}</li>
      </ul>
    </div>
  </div>
);

const TypeSection = ({ children, ...props }: Props) => (
  <ul
    className="navigation navigation__list navigation__global--type"
    {...props}
  >
    {children}
  </ul>
);

const LanguageSection = ({ children, ...props }: Props) => (
  <ul
    className="navigation navigation__list navigation__global--language"
    {...props}
  >
    {React.Children.map(children, child => (
      <li className="navigation__item navigation__item--right hide--md hide--sm">
        {child}
      </li>
    ))}
  </ul>
);

type TabProps = {
  isSelected?: boolean
} & Props;

const Tab = ({ children, isSelected, ...props }: TabProps) => (
  <NavItem className="hide--md hide--sm">
    <Link
      {...props}
      type="htmlLink"
      className={classnames(
        "navigation__link navigation__link--slim navigation__site",
        {
          "navigation__site--active": isSelected
        }
      )}
    >
      {children}
    </Link>
  </NavItem>
);

export { TopBar, TypeSection, LanguageSection, Tab };
