// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Table-module__table--3C6Tf{table-layout:fixed}\n", ""]);
// Exports
exports.locals = {
	"table": "Table-module__table--3C6Tf"
};
module.exports = exports;
