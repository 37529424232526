/* @flow */

import * as React from "react";

type Props = {
  children: React.Element<React.ElementType>, // Must be an element and not a text
  isOpen?: boolean,
  component?: React.ElementType,
  onAnimationCompleted?: (isOpen: boolean) => void,
  transitionTime?: number,
  transitionType?: string
};

type State = {
  isOpen?: boolean,
  animationStatus: "START" | "PROGRESS" | "END"
};

export default class SlideToggle extends React.Component<Props, State> {
  state = { isOpen: this.props.isOpen, animationStatus: "END" };
  _ref: { current: ?HTMLElement } = React.createRef();

  static getDerivedStateFromProps(newProps: Props, oldState: State) {
    if (Boolean(newProps.isOpen) !== Boolean(oldState.isOpen)) {
      return {
        isOpen: newProps.isOpen,
        animationStatus: "START"
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.state.animationStatus === "START") {
      this.setState({
        animationStatus: "PROGRESS"
      });
    }
  }

  _getChildHeight = (): string => {
    // $FlowFixMe: Tooling issues around using the createRef<T> form
    const element: ?HTMLElement = this._ref.current;

    if (!element) {
      return "auto";
    }

    const elementChild: ?HTMLElement = element.children[0];

    if (!elementChild) {
      return "auto";
    }

    return `${elementChild.offsetHeight}px`;
  };

  _getHeightFromAnimationState = (): string => {
    const elementHeight: string = this._getChildHeight();
    const animationStatusHeightMap = {
      opening: {
        START: "0px",
        PROGRESS: elementHeight,
        END: "auto"
      },
      closing: {
        START: elementHeight,
        PROGRESS: "0px",
        END: "0px"
      }
    };

    return animationStatusHeightMap[this.state.isOpen ? "opening" : "closing"][
      this.state.animationStatus
    ];
  };

  _handleTransitionEnd = (
    event: SyntheticTransitionEvent<HTMLElement>
  ): void => {
    event.stopPropagation();

    this.setState(
      {
        animationStatus: "END"
      },
      () => {
        if (this.props.onAnimationCompleted) {
          this.props.onAnimationCompleted(this.state.isOpen || false);
        }
      }
    );
  };

  render() {
    const {
      transitionTime = 1000,
      transitionType = "",
      children,
      component: Component = "div"
    } = this.props;

    return (
      <Component
        ref={this._ref}
        onTransitionEnd={this._handleTransitionEnd}
        style={{
          overflow: "hidden",
          transition: `height ${transitionTime}ms ${transitionType}`,
          height: this._getHeightFromAnimationState(),
          display:
            !this.state.isOpen && this.state.animationStatus === "END"
              ? "none"
              : undefined
        }}
      >
        {children}
      </Component>
    );
  }
}
