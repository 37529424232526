// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Heading-module__heading--dark--3kVLW{color:#fff}\n", ""]);
// Exports
exports.locals = {
	"heading--dark": "Heading-module__heading--dark--3kVLW"
};
module.exports = exports;
