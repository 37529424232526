/* @flow */

import * as React from "react";
import classnames from "classnames";
import pluralize from "pluralize";
import { Svg } from "@vf-dcl/vodafone-ws2";
import FilterContext from "./_FilterContext";
import Icon from "../Icon";

const FilterButton = () => (
  <FilterContext.Consumer>
    {({ isOpen, toggleSidebar, activeFilters, clearFilters }) => {
      let allFilters = [];
      Object.keys(activeFilters).forEach(filter => {
        allFilters = allFilters.concat(activeFilters[filter]);
      });

      return (
        <div className="filters__counter-toggle">
          <button
            className={classnames("filters__sidebar-toggle", {
              "filters__sidebar-toggle--active": isOpen
            })}
            onClick={toggleSidebar}
          >
            <span className="caption">
              <span className="caption__text">
                <span className="filters__counter">{allFilters.length}</span>
                <span>{pluralize(" Filter", allFilters.length)}</span>
              </span>
              <span className="caption__media">
                <Icon
                  source={Svg.ChevronDown}
                  size="extra-small"
                  className="actions__icon actions__icon--chevron"
                />
              </span>
            </span>
          </button>
          <button className="filters__clear" onClick={clearFilters}>
            {pluralize("Clear filter", activeFilters.length)}
          </button>
        </div>
      );
    }}
  </FilterContext.Consumer>
);

export default FilterButton;
