/* @flow */
import * as React from "react";
import Icon from "../Icon";
import styles from "./Loader.module.scss";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";

type Props = {
  radius?: number,
  color?:
    | "wild-sand"
    | "white"
    | "tundora"
    | "tangerine"
    | "supernova"
    | "silver-chalice"
    | "silver"
    | "seance"
    | "red-berry"
    | "red"
    | "provincialpink"
    | "porcelain"
    | "pistachio"
    | "mine-shaft-light"
    | "mine-shaft-dark"
    | "mine-shaft"
    | "mercury-dark"
    | "mercury"
    | "limeade"
    | "ironside-gray"
    | "guardsman-red"
    | "green"
    | "gallery"
    | "finn"
    | "dove-gray"
    | "dusty-gray"
    | "cerulean"
    | "buddha-gold"
    | "blue-lagoon"
    | "black"
    | "amethyst"
    | "alto"
    | "abbey"
};

const Loader = ({ radius = 25, color = "cerulean" }: Props) => {
  return (
    <div
      className={classnames(styles["lds-ring"], styles[`lds-ring--${color}`])}
    >
      <_LoaderPart radius={radius} />
      <_LoaderPart radius={radius} />
      <_LoaderPart radius={radius} />
      <_LoaderPart radius={radius} />
      <_LoaderPart radius={radius} />
    </div>
  );
};

const _LoaderPart = ({ radius = 25 }: Props) => (
  <div style={{ height: radius * 2, width: radius * 2 }} />
);

export default Loader;
