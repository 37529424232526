/* @flow */

import FormField, { type FormFieldProps } from "./_FormField";

import DateTimePicker from "../DateTimePicker";
import React from "react";
import moment from "moment";

type Props = {
  name: string,
  value?: string,
  isDisabled?: boolean,
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChange: string => void,
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void
} & FormFieldProps;

const FormInput = ({
  label,
  value,
  name,
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  onFocus,
  onChange,
  onBlur,
  ...props
}: Props) => {
  const StringToDate = (
    dateString: string,
    format: string = "DD/MM/YYYY"
  ): Date => new Date(moment(dateString, format).format("L"));

  const [selectedDate, changeSelectedDate] = React.useState("");

  React.useEffect(() => {
    if (value && moment(value, "DD/MM/YYYY", true).isValid()) {
      changeSelectedDate(StringToDate(value));
    }
  }, [value]);

  const handleChange = (date: Date) => {
    changeSelectedDate(date);
    if (onChange) onChange(date.toLocaleDateString("en-GB"));
  };

  return (
    <FormField
      {...{
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden
      }}
    >
      <DateTimePicker
        {...props}
        {...{ name, onFocus, onBlur }}
        selected={selectedDate}
        onChange={handleChange}
        required={isRequired}
        disabled={isDisabled}
        strictParsing={true}
      />
    </FormField>
  );
};

export default FormInput;
