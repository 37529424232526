/* @flow */

import classnames from "classnames";
import * as React from "react";
import Clickable, { type ClickableAction } from "../Clickable";
import List from "../List";
import Section from "../Section";
import Spring from "../Spring";

type Props = {
  children: React.Node
};

type ListProps = {
  list: $ReadOnlyArray<{
    label: React.Node,
    action: ClickableAction
  }>
};

const FooterList = ({ list }: ListProps) => {
  return (
    <List isReset className="footer__contracts">
      {list.map(({ label, action }, index) => (
        <List.Item
          className={classnames("footer__contract", {
            "footer__contract--first": index === 0
          })}
          key={index}
        >
          <Clickable {...action}>{label}</Clickable>
        </List.Item>
      ))}
    </List>
  );
};

const FooterAside = ({ children }: { children: React.Node }) => {
  return <div className="footer__aside">{children}</div>;
};

export default class Footer extends React.Component<Props> {
  static List = FooterList;
  static Aside = FooterAside;

  render() {
    const { children } = this.props;

    return (
      <div className="footer">
        <div className="footer__curtain">
          <Spring>
            <Section color="transparent">{children}</Section>
          </Spring>
        </div>
      </div>
    );
  }
}
