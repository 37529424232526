/* @flow */

import * as React from "react";

type Props = {
  radius: number,
  percentageStyle: string
};

const DialCircle = ({ radius, percentageStyle }: Props) => {
  return (
    <svg
      className="dial__svg"
      viewBox="0 0 500 500"
      preserveAspectRatio="xMinYMin meet"
    >
      <circle
        className="dial__circle-track"
        cx="250"
        cy="250"
        r={radius}
        transform="rotate(-89.8 250 250)"
      ></circle>
      <circle
        className="dial__circle"
        cx="250"
        cy="250"
        r={radius}
        transform="rotate(-89.8 250 250)"
        style={{ strokeDasharray: percentageStyle }}
      ></circle>
    </svg>
  );
};

export default DialCircle;
