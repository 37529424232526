/* @flow */

import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Icon from "../Icon";
import styles from "./Step.module.scss";

type Props = {
  children: React.Node,
  /** The type react node is to accept more complex rendering components */
  number: React.Node,
  status?: "completed" | "active" | "warning",
  component?: React.ElementType,
  /** If defined the steps will be clickable */
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void
};

const Step = ({ children, number, status, component, onClick }: Props) => {
  const MarkupComponent = component || "div";
  const DotComponent = onClick ? "button" : "span";

  return (
    <MarkupComponent
      className={classnames(styles["step"], {
        [styles["step--active"]]: status === "active",
        [styles["step--completed"]]: status === "completed",
        [styles["step--warning"]]: status === "warning",
        [styles["step--inactive"]]: status !== "active"
      })}
    >
      <span className={styles["step__title"]}>{children}</span>
      <DotComponent onClick={onClick} className={styles["step__dot"]}>
        {(currentStatus => {
          switch (currentStatus) {
            case "warning":
              return <Icon source={Svg.Warning} />;
            case "completed":
              return <Icon source={Svg.Tick} />;
            default:
              return number;
          }
        })(status)}
      </DotComponent>
    </MarkupComponent>
  );
};

export default Step;
