/* @flow */

import * as React from "react";
import { type FilterSortOptionProps } from "./types";

const FilterSortOption = ({ children, value }: FilterSortOptionProps) => {
  return (
    <option value={value} key={value}>
      {children}
    </option>
  );
};

export default FilterSortOption;
