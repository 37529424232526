/* @flow */
import { Svg } from "@vf-dcl/vodafone-ws2";
import * as React from "react";
import Icon from "../Icon";
import Button from "../Button";

type Props = {
  /** Passes a placeholder value for the input  */
  placeholder?: string,
  /** Adds dark theme to the submit button  */
  isDark?: boolean,
  /** Handles the onSubmit event  */
  onSubmit?: () => void,
  /** Handles the onClick event of the "x" shaped button  */
  onClear: () => void,
  /** Handles the onChange event of the input field  */
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  /** Handles the value that is passed to the input field  */
  value: string
};

const Search = ({
  placeholder = "Search",
  isDark,
  onSubmit,
  value,
  onClear,
  onChange,
  ...props
}: Props) => (
  <div className="input-group">
    <input
      id="search-snack-input"
      className="input-group__input form__input form__text"
      required
      placeholder={placeholder}
      type="text"
      value={value}
      onChange={onChange}
      {...props}
    />
    <button className="input-group__clear" onClick={onClear}>
      <Icon
        source={Svg.Close}
        size="small"
        className="input-group__clear-icon"
        onClick={onClear}
      />
    </button>
    <Button
      name="search"
      rank="primary"
      className="input-group__submit"
      darkBackground={isDark}
      disabled={!value}
      onClick={onSubmit}
    >
      <Icon
        source={Svg.Search}
        size="small"
        className="chevron__icon input-group__submit-icon"
      />
    </Button>
  </div>
);

export default Search;
