/* @flow */

import memoizeOne from "memoize-one";
import * as React from "react";

const sortByMaxWidth = (
  items: $ReadOnlyArray<BreakpointImage>
): $ReadOnlyArray<BreakpointImage> =>
  items.slice().sort((a, b) => a.maxWidth - b.maxWidth);

export type BreakpointImage = {
  source: string,
  maxWidth: number
};

export type ResponsiveImageSet = {
  images?: $ReadOnlyArray<BreakpointImage>,
  imageBase: string
};

type Props = ResponsiveImageSet & {
  altText: string,
  className?: string,
  onSourceLoad?: (source: string) => void,
  isHidden?: boolean
};

export default class PictureLoader extends React.Component<Props> {
  _memoizedSort = memoizeOne(sortByMaxWidth);

  _handleSourceLoaded = ({
    currentTarget
  }: SyntheticEvent<HTMLImageElement>) => {
    if (this.props.onSourceLoad) {
      this.props.onSourceLoad(currentTarget.currentSrc);
    }
  };

  render() {
    const { imageBase, images, altText, className, isHidden } = this.props;

    return (
      <>
        <picture
          style={
            isHidden ? { display: "none" } : { lineHeight: 0, display: "block" }
          }
        >
          {images
            ? this._memoizedSort(images).map(({ source, maxWidth }) => (
                <source
                  key={maxWidth}
                  srcSet={source}
                  media={`(max-width: ${maxWidth}px)`}
                />
              ))
            : null}
          <img
            srcSet={imageBase}
            alt={altText}
            className={className}
            onLoad={this._handleSourceLoaded}
          />
        </picture>
      </>
    );
  }
}
