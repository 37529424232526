/** @flow */

import * as React from "react";

import Button from "../Button";
import Heading from "../Heading";
import Icon from "../Icon";
import Link from "../Link";
import NavItem from "./_NavItem";
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";

type IconProps = {
  /** The SVG loaded, this will accept any SVG but the WS2 ones a recommended */
  source: SVGImport,
  /** The visually hidden accessability text */
  label?: string,
  /** Used to show a menu below the Icon, such as the <LoginPrompt />  */
  children?: React.Node,
  /** Show's content over the Icon */
  extraContent?: React.Node
};

const NavIcon = ({
  source = Svg.MyVodafone,
  label = "link",
  children,
  extraContent,
  ...props
}: IconProps) => (
  <NavItem
    className={classnames(
      "navigation__item--right navigation__hide login-nudge",
      {
        "login-nudge--active": children
      }
    )}
  >
    <Link
      {...props}
      type="htmlLink"
      className="navigation__link navigation__link--icon login-nudge__link"
    >
      {extraContent}
      <span className="visually-hidden">{label}</span>
      <Icon source={source} size="small" className="navigation__icon" />
    </Link>
    {children}
  </NavItem>
);

NavIcon.LoginPrompt = ({ loginButtonProps, cancelButtonProps, ...props }) => (
  <div className="login-nudge__prompt" {...props}>
    <Heading
      component="p"
      fontWeight="light"
      level={3}
      className="no-gutter--top no-gutter--bottom"
    >
      Welcome
    </Heading>
    <p className="no-gutter--top">Want to login in?</p>
    <Button
      rank="primary"
      layout="fullWidth"
      className="login-nudge__login"
      {...loginButtonProps}
    >
      Yes, log me in
    </Button>
    <Button
      rank="secondary"
      layout="fullWidth"
      className="no-gutter--right no-gutter--bottom"
      {...cancelButtonProps}
    >
      No thanks
    </Button>
  </div>
);

export default NavIcon;
