/* @flow */
import { Svg } from "@vf-dcl/vodafone-ws2";
import classnames from "classnames";
import * as React from "react";
import Icon from "../Icon";

type Props = {
  /** Specifies input type attribute value. */
  type?: "radio" | "checkbox",
  /** Props to add to the input component. */
  inputProps?: {
    /** Specifies input name attribute value. */
    name?: string,
    /** Specifies input value attribute value. */
    value?: string,
    /** Specifies if input is disabled. */
    disabled?: boolean,
    /** Specifies if input is required. */
    required?: boolean
  },
  /** Defines the checked state of the component */
  isChecked?: boolean,
  /** Marks if the check has border styling. */
  hasBorder?: boolean,
  /** An icon for the checked svg icon property. */
  checkedIcon?: SVGImport,
  /** An icon for the unchecked svg icon property. */
  uncheckedIcon?: SVGImport,
  /** Checkbox label content. */
  content: React.Node,
  /** Specifies CSS classes to be added to the root .check.  */
  rootClasses?: string,
  /** Specifies CSS classes to be added to the content .check__content. */
  contentClasses?: string,
  /** The function called when the state is changed */
  onChange: string => void,
  /** The additional content of the check. */
  children?: React.Node
};

const Check = ({
  type = "checkbox",
  inputProps,
  isChecked,
  hasBorder = true,
  checkedIcon = Svg.Tick,
  uncheckedIcon = Svg.Plus,
  content,
  rootClasses,
  contentClasses,
  onChange,
  children
}: Props) => (
  <div
    className={classnames("check", { "check--border": hasBorder }, rootClasses)}
  >
    <label className="check__main">
      <input
        type={type}
        {...inputProps}
        checked={isChecked}
        onChange={e => onChange?.(e.target.checked)}
        className={classnames("check__input", "visually-hidden")}
      />
      <span className="check__check">
        <Icon
          source={checkedIcon}
          size="extra-small"
          className={classnames("check__icon", "check__icon--checked")}
        />
        <Icon
          source={uncheckedIcon}
          size="extra-small"
          className={classnames("check__icon", "check__icon--unchecked")}
        />
      </span>
      <span className={classnames("check__content", contentClasses)}>
        {content}
      </span>
    </label>
    {children}
  </div>
);

type FooterType = {
  /** The additional content of the check. */
  children?: React.Node
};

const CheckFooter = ({ children }: FooterType) => (
  <div className="check__footer">{children}</div>
);

Check.Footer = CheckFooter;
export default Check;
