/* @flow */

import * as React from "react";

type Props = {
  getToggleAllRowsSelectedProps: any
};

const AdvancedTableCheckBoxHeader = ({
  getToggleAllRowsSelectedProps
}: Props) => (
  <div style={{ textAlign: "center", margin: "auto", width: "20px" }}>
    <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
  </div>
);

export default AdvancedTableCheckBoxHeader;
