/* @flow */

import * as React from "react";

import AdvancedTableContext from "./_AdvancedTableContext";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Table from "../Table";
import FlexTable from "../FlexTable";
import classnames from "classnames";
import styles from "./AdvancedTable.module.scss";

const AdvancedTableSimpleHeader = () => {
  const { layout, headerGroups, resizeEnabled } = React.useContext(
    AdvancedTableContext
  );
  const MarkupComponent = layout === "flex" ? FlexTable : Table;

  return (
    <MarkupComponent.Header>
      {headerGroups.map((headerGroup, index) => (
        <MarkupComponent.Row
          isHeader
          key={index}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column, index) => (
            <React.Fragment key={index}>
              <MarkupComponent.Heading {...column.getHeaderProps()}>
                {column.render("Header")}
              </MarkupComponent.Heading>
              {column.canResize && resizeEnabled && (
                <div
                  {...column.getResizerProps()}
                  className={classnames(styles["advanced-table__resizer"], {
                    [styles["advanced-table__resizer--is-resizing"]]:
                      column.isResizing
                  })}
                />
              )}
            </React.Fragment>
          ))}
        </MarkupComponent.Row>
      ))}
    </MarkupComponent.Header>
  );
};

export default AdvancedTableSimpleHeader;
