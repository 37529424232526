// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Stepper-module__stepper--2zdml{width:100%;display:flex;flex-direction:row;justify-content:space-between;position:relative;margin:0;padding:0}.Stepper-module__stepper--2zdml::after{content:\"\";position:absolute;left:5px;right:5px;bottom:23px;height:2px;background:#ebebeb}\n", ""]);
// Exports
exports.locals = {
	"stepper": "Stepper-module__stepper--2zdml"
};
module.exports = exports;
