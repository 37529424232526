/* @flow */
import * as React from "react";
import ReactSelect from "react-select";
import { Svg } from "@vf-dcl/vodafone-ws2";
import ClearIndicator from "./_ClearIndicator.web";
import DropdownIndicator from "./_DropdownIndicator.web";
import localStyles from "./styles";

type Props = {
  options: Array<{ label: string | number, value: string | number }>,
  styles?: { [string]: () => Object }
};

const Select = ({ options, styles = {}, ...props }: Props) => (
  <ReactSelect
    components={{ ClearIndicator, DropdownIndicator }}
    options={options}
    styles={{ ...localStyles, ...styles }}
    {...props}
  />
);

export default Select;
