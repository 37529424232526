/* @flow */

import classnames from "classnames";
import * as React from "react";
import Content from "./_AccordionContent";
import AccordionContext from "./_AccordionContext";
import Heading from "./_AccordionHeading";
import Item from "./_AccordionItem";
import LinkItem from "./_AccordionLinkItem";
import { type ActiveMap } from "./types";

type Props = {
  isRadio?: boolean,
  className?: string,
  defaultActive?: string | ActiveMap,
  component?: React.ElementType,
  children?: React.Node,
  isBilling?: boolean,
  /** Only works with isBilling */
  isDark?: boolean
};

type State = {
  active: ActiveMap,
  toggleActive: (itemKey: string) => void,
  isBilling?: boolean
};

export default class Accordion extends React.Component<Props, State> {
  static Item = Item;
  static LinkItem = LinkItem;
  static Heading = Heading;
  static Content = Content;
  static defaultProps = {
    isBilling: false
  };

  _toggleActive = (itemKey: string) => {
    this.setState(({ active }, { isRadio }) => ({
      active: isRadio
        ? normaliseMap(itemKey)
        : {
            ...active,
            [itemKey]: !active[itemKey]
          }
    }));
  };

  // NOTE: The flow type for child is $NonMaybeType<T>, therefore mapping to any.
  _mapItemKeys = (child: any, index: number) =>
    // NOTE: Assign keys to direct Item children
    child !== null && child !== undefined
      ? React.cloneElement(
          child,
          Item.isAccordionItem(child.type) &&
            (!child.props || !child.props.itemKey)
            ? { itemKey: String(index) }
            : {}
        )
      : null;

  state = {
    active: this._getInitialActive(),
    toggleActive: this._toggleActive,
    isBilling: this.props.isBilling
  };

  render() {
    const {
      component: Component = "div",
      className = "",
      isBilling,
      isDark
    } = this.props;

    return (
      <Component
        className={classnames(
          {
            accordion: !isBilling,
            "bills-accordion": isBilling,
            "bills-accordion--dark": isDark && isBilling
          },
          className
        )}
      >
        <AccordionContext.Provider value={this.state}>
          {React.Children.map(this.props.children, this._mapItemKeys)}
        </AccordionContext.Provider>
      </Component>
    );
  }

  _getInitialActive() {
    const active = normaliseMap(this.props.defaultActive);
    if (this.props.isRadio) {
      const activeKeys = Object.keys(active);
      if (activeKeys.length > 1) {
        // TODO: when we have events, just setting the initial state won't be
        // enough - we also need to emit a change event in componentDidMount
        // (not here!)
        return normaliseMap(activeKeys[0]);
      }
    }
    return active;
  }
}

function normaliseMap(keyOrMap?: string | ActiveMap): ActiveMap {
  if (!keyOrMap) {
    return {};
  }
  if (typeof keyOrMap === "string") {
    return { [keyOrMap]: true };
  }
  return keyOrMap;
}
