// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SnackBar-module__snack-bar--2hKVM{width:100%;position:fixed;right:0}.SnackBar-module__snack-bar--2hKVM .SnackBar-module__close--1zBYo{position:absolute;right:20px;cursor:pointer}.SnackBar-module__snack-bar--relative--1baNj{position:relative;right:initial;z-index:initial}.SnackBar-module__snack-bar--top--Qep4I{top:-72px;transition:top 0.3s ease-in-out}.SnackBar-module__snack-bar--bottom--3z0N6{bottom:-72px;transition:bottom 0.3s ease-in-out}.SnackBar-module__snack-bar--visible-top--2L6iA{top:0}.SnackBar-module__snack-bar--visible-bottom--3z-SQ{bottom:0}\n", ""]);
// Exports
exports.locals = {
	"snack-bar": "SnackBar-module__snack-bar--2hKVM",
	"close": "SnackBar-module__close--1zBYo",
	"snack-bar--relative": "SnackBar-module__snack-bar--relative--1baNj",
	"snack-bar--top": "SnackBar-module__snack-bar--top--Qep4I",
	"snack-bar--bottom": "SnackBar-module__snack-bar--bottom--3z0N6",
	"snack-bar--visible-top": "SnackBar-module__snack-bar--visible-top--2L6iA",
	"snack-bar--visible-bottom": "SnackBar-module__snack-bar--visible-bottom--3z-SQ"
};
module.exports = exports;
