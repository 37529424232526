/* @flow */

import type { optionProps } from "./SelectMultiDropdown";

type Props = Array<optionProps>;

const filterOptions = (query: string, options: Props): Props => {
  if (query.length) {
    const matches = options.filter(
      option =>
        `${option.title}`.toLowerCase().includes(query.toLowerCase()) ||
        (option.groupOptions || []).filter(groupOption =>
          groupOption.title.toLowerCase().includes(query.toLowerCase())
        ).length
    );
    const data = matches.reduce((acc, option) => {
      const groupOptions =
        option.groupOptions &&
        option.groupOptions.filter(grpOpt =>
          grpOpt.title.toLowerCase().includes(query.toLowerCase())
        );
      const opt = {
        ...option,
        groupOptions
      };
      return [...acc, opt];
    }, []);
    return data;
  } else {
    return options;
  }
};

export default filterOptions;
