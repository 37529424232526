/* @flow */

import * as React from "react";
import { Svg } from "@vf-dcl/vodafone-ws2";
import Button from "../Button";
import Heading from "../Heading";
import Spring from "../Spring";
import FileComp from "./_File";
import localStyles from "./Upload.module.scss";

type Props = {
  /** Accepted file types */
  accept?: string,
  /** Initial uploaded files */
  initialValue?: Array<File>,
  /** Maximum file size (in bytes) - beware that 1MB = 1048576 bytes on disk */
  maxSize?: number,
  /** Maximum number of files */
  maxFiles?: number,
  /** Handles the file uploads - also expected to update on file upload progress */
  onUpload: (file: File, updateProgress: (progress: number) => void) => void,
  /** Handles removing a file from where it was uploaded to */
  onRemoval: (file: File) => void,
  /** Handle any validation errors */
  onError: (message: string) => void
};

const Upload = ({
  accept = "",
  initialValue = [],
  maxSize,
  maxFiles,
  onUpload,
  onRemoval,
  onError
}: Props) => {
  const [files, setFiles] = React.useState(initialValue);
  const inputRef: { current: ?HTMLInputElement } = React.useRef(null);

  const acceptMap = accept.split(",").reduce((acc, val) => {
    acc[val] = true;
    return acc;
  }, {});

  const preventDefault = (e: SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const validateFiles = (newFiles: Array<File>): Array<File> => {
    console.log(newFiles[0].name, newFiles[0].size);
    if (maxFiles && files.length + newFiles.length > maxFiles) {
      onError("Maximum number of files exceeded");
      return [];
    }

    const errors = [];
    const acceptedFiles = newFiles.filter((file: File) => {
      if (accept && !acceptMap[file.type]) {
        errors.push(`${file.name} has an unsupported file type`);
        return false;
      }

      if (maxSize && file.size > maxSize) {
        errors.push(`${file.name} is larger than the maximum size`);
        return false;
      }

      return true;
    });

    if (errors.length > 0) {
      onError(errors.join(", "));
    }
    return acceptedFiles;
  };

  const onDrop = e => {
    preventDefault(e);

    const newFilesObj: { [key: number]: File } = e.dataTransfer
      ? e.dataTransfer.files
      : e.target.files;
    // $FlowFixMe
    const newFiles: Array<File> = Object.values(newFilesObj);
    const withoutDuplicates = newFiles.filter(
      newFile => !files.find(x => x.name === newFile.name)
    );
    const acceptedFiles = validateFiles(withoutDuplicates);
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  };

  // $FlowFixMe
  const openFileInput = () => inputRef.current.click();

  const removeFile = (fileToRemove: File) => {
    onRemoval(fileToRemove);
    setFiles(files.filter(file => file.name !== fileToRemove.name));
  };

  return (
    <>
      <div
        className={localStyles["upload-container"]}
        onDrop={onDrop}
        onDragEnter={preventDefault}
        onDragLeave={preventDefault}
        onDragOver={preventDefault}
      >
        <Spring size="small">
          <div className={localStyles["upload-text"]}>
            <input
              id="upload-input"
              className={localStyles["upload-input"]}
              type="file"
              onChange={onDrop}
              accept={accept}
              multiple={maxFiles !== 1}
              ref={inputRef}
            />
            <Heading level={4} isCenterAligned hasNoGutter>
              Drag and drop files here to upload
            </Heading>
            <Heading level={4} isCenterAligned hasNoGutter>
              or
            </Heading>
            <Button
              className={localStyles["browse-button"]}
              rank="tertiary"
              onClick={openFileInput}
              layout="fullWidth"
            >
              Browse files
            </Button>
          </div>
        </Spring>
      </div>
      <div className={localStyles["file-list-container"]}>
        {files.map(file => (
          <div key={file.name} className={localStyles["file-container"]}>
            <FileComp file={file} onUpload={onUpload} onRemoval={removeFile} />
          </div>
        ))}
      </div>
    </>
  );
};

export default Upload;
