/* @flow */

import * as React from "react";
import { type ItemContext } from "./types";

const AccordionItemContext = React.createContext<ItemContext>({
  isActive: false,
  toggleActive: () => {},
  isBilling: false
});

export default AccordionItemContext;
