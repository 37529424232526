/* @flow */

import * as React from "react";
import Table from "../Table";
import FlexTable from "../FlexTable";
import AdvancedTableContext from "./_AdvancedTableContext";

type Props = {
  children: React.Node
};

const AdvancedTableTable = ({ children, ...props }: Props) => {
  const { layout, getTableProps } = React.useContext(AdvancedTableContext);
  const MarkupComponent = layout === "flex" ? FlexTable : Table;

  return (
    <MarkupComponent {...getTableProps()} {...props}>
      {children}
    </MarkupComponent>
  );
};
export default AdvancedTableTable;
