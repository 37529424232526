/* @flow */

import * as React from "react";
import { type FilterHeadingProps } from "./types";
import Grid from "../Grid";
import { Svg } from "@vf-dcl/vodafone-ws2";

const FilterHeading = ({ children, ...gridProps }: FilterHeadingProps) => {
  const gridCount = React.Children.count(children);
  return (
    <div className="actions">
      <Grid {...gridProps}>
        {React.Children.map(children, component => (
          <Grid.Item
            // $FlowIssue - Flow doesn't like dynamically calculating the amount of grid items
            width={`1/${gridCount}`}
            smWidth="1/1"
            mdWidth="1/2"
            verticalAlign="middle"
          >
            {component}
          </Grid.Item>
        ))}
      </Grid>
    </div>
  );
};

export default FilterHeading;
